import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/auth/Login';
import Signup from '../pages/auth/Signup';
import PortalRoutes from './PortalRoutes';
import ForgotPassword from '../pages/auth/ForgotPassowrd';
import CodeVerification from '../pages/auth/CodeVerification';
import NewPassword from '../pages/auth/NewPassowrd';
import PrivateRoute from './PrivateRoute';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/code-verification" element={<CodeVerification />} />
        <Route path="/new-password" element={<NewPassword />} />

        {/* Protected routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/*" element={<PortalRoutes />} />
        </Route>

        {/* Redirect to login if accessing root */}
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;