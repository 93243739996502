import React, { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import Input from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import { Icon } from '@iconify/react';
import './ProfileSettings.scss';
import { useAuth } from '../../hooks/useAuth';
import { updateDocument } from '../../firebase/firebaseHelpers';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { auth } from '../../firebase/firebase';

const ProfileSettings = () => {
  const [form] = Form.useForm();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
console.log(user,"user")
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        username: user.username,
        email: user.email,
      });
    }
  }, [user, form]);

  
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { email, currentPassword, newPassword, confirmNewPassword, ...updateData } = values;
  
      // Get the current user
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error('No user is currently signed in');
      }
  
      // Verify the current password
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
  
      // If new password is provided, update it
      if (newPassword) {
        await updatePassword(user, newPassword);
      }
  
      // Update other user data in Firestore
      await updateDocument('users', user.uid, updateData);
  
      message.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      
      // Provide more specific error messages
      if (error.code === 'auth/wrong-password') {
        message.error('Current password is incorrect');
      } else if (error.code === 'auth/weak-password') {
        message.error('New password is too weak');
      } else {
        message.error(error.message || 'Failed to update profile');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-settings-container">
      <div className="profile-settings-form-wrapper">
        <div className="avatar-container">
          <img src={user?.photoURL || require('../../assets/images/bodybg.png')} alt="User Avatar" className="avatar-image" />
          <div className="avatar-edit-icon d-flex justify-content-center align-items-center">
            <Icon icon="mdi:pencil"  />
          </div>
        </div>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input icon="mdi:account-outline" label="Username" placeholder="Andrew Rapchat" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
            <Input icon="majesticons:mail-line" label="Email" placeholder="example12@gmail.com" Disabledbordered value={user.email} />
          </Form.Item>
          <Form.Item name="currentPassword" rules={[{ required: true, message: 'Please input your current password!' }]}>
            <Input icon="ph:lock" label="Current Password" type="password" placeholder="●●●●●●●●" />
          </Form.Item>
          <Form.Item name="newPassword" rules={[{ required: true, message: 'Please input your new password!' }]}>
            <Input icon="ph:lock" label="New Password" type="password" placeholder="●●●●●●●●" />
          </Form.Item>
          <Form.Item name="confirmNewPassword" dependencies={['newPassword']} rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}>
            <Input icon="ph:lock" label="Confirm New Password" type="password" placeholder="●●●●●●●●" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="w-100"  loading={loading}>
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfileSettings;