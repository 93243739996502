import React from 'react';
import { Select as AntSelect } from 'antd';
import { Icon } from '@iconify/react';
import './Select.scss';

const { Option } = AntSelect;

export const Select = ({ label, options, disabled, ...props }) => {
  return (
    <div className={`custom-select-wrapper ${disabled ? 'disabled' : ''}`}>
      {label && <label className="select-label">{label}</label>}
      <AntSelect
    
        suffixIcon={<Icon icon="mdi:chevron-down" className="select-icon" />}
        disabled={disabled}
        {...props}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </AntSelect>
    </div>
  );
};