// src/pages/auth/Login.jsx
import React from 'react';
import { Checkbox, Form,  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/common/Input';
import './Login.scss';
import {Button} from '../../components/common/Button';

const CodeVerification = () => {
  const [form] = Form.useForm();
  const navigate =useNavigate()
  const handleSubmit = () => {
      form.validateFields().then(values => {
        console.log('Form values:', values);
        navigate('/new-password');
      }).catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });
    };
  
    

  return (
    <div className=''>
      <div className="text-center my-5">
      <div className="col-md-6 m-auto">

<img src={require('../../assets/images/fulllogo.png')} className='w-100' />
</div>
</div>

<div className="login-container p-3">
      <div className="login-form-wrapper border-primary">
        <h2 className="login-title">Code Verification!</h2>
        <p className="login-subtitle">Please enter the code sent to your email address</p>
        <Form form={form} onFinish={handleSubmit} layout="vertical" >
          <Form.Item className='my-5' name="code" rules={[{ required: true, message: 'Please input your code!' }]}>
            <Input icon="majesticons:mail-line" label="code" placeholder="Andrew Rapchak" />
          </Form.Item>
       
       
       
         
         

          <Form.Item>
            <Button  htmlType="submit" className="sign-up-btn">
              Send
            </Button>
        <p className="login-footer text-right mt-1">
            Resend
        </p>
          </Form.Item>
        </Form>
      </div>
    </div>
    </div>
  );
};

export default CodeVerification;