import { Avatar, Button, Col, Row } from "antd";
import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substring(0, 2), 16);
  const g = parseInt(hexcolor.substring(2, 4), 16);
  const b = parseInt(hexcolor.substring(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

const HeaderComponenet = ({collapsed,toggleSidebar,isSmallScreen}) => {
  const { user } = useAuth();

  const avatarBackgroundColor = stringToColor(user?.username);
  const avatarTextColor = getContrastYIQ(avatarBackgroundColor);

  return (
    <div className="header-container  " style={{position:'relative',top:-60,right:30}}>
      <Row justify="end" align="middle" className="full-width">
   
        <Col>
          <div className="user-info text-white d-flex align-items-center gap-2 ">
            <span className="user-email mr-3">
              {user?.email ?? "under-dev@salute.com"}
            </span>
            <Avatar
              size={40}
              style={{
                backgroundColor: avatarBackgroundColor,
                color: avatarTextColor,
              }}
            >
              {user?.username[0]}
            </Avatar>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderComponenet;
