import { signOut, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase/firebase';
import { getDocumentById } from '../firebase/firebaseHelpers';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const [isAuthListenerActive, setIsAuthListenerActive] = useState(false);

  // console.log(user, "get userData api hit");

  const startAuthListener = useCallback(() => {
    console.log(isAuthListenerActive)
    if (!isAuthListenerActive) {
      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        if (firebaseUser) {
          try {
            const userData = await getDocumentById('users', firebaseUser.uid);
            if (userData) {
              setUser(userData);
              localStorage.setItem('user', JSON.stringify(userData));
              localStorage.setItem('userId',firebaseUser.uid );

              
            } else {
              const newUser = {
                id: firebaseUser.uid,
                email: firebaseUser.email,
              };
              setUser(newUser);
              localStorage.setItem('user', JSON.stringify(newUser));
      setIsAuthListenerActive(true);

            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        } else {
          setUser(null);
          localStorage.removeItem('user');
          // logout()
        }
        setLoading(false);
      });
      setLoading(false);

      return unsubscribe;
    }
    
  }, [isAuthListenerActive]);

  useEffect(() => {
    const unsubscribe = startAuthListener();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [startAuthListener]);

  const login = useCallback((userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }, []);

  const updateUser = (updatedData) => {
    const newUserData = { ...user, ...updatedData };
    setUser(newUserData);
    localStorage.setItem('user', JSON.stringify(newUserData));
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('user');
      localStorage.clear();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};