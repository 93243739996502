import React, { useState, useEffect } from "react";
import { ANT_Table } from "../../utils";
import { Button, Modal, Form, Input, Switch, message } from "antd";
import { getAllDocuments, updateDocument } from "../../firebase/firebaseHelpers";
import { getAuth } from "firebase/auth";

const AllCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const fetchedCustomers = await getAllDocuments("users");
      const jobs = await getAllDocuments("jobs");
      
      const customersWithServices = fetchedCustomers
        .filter(user => user.userType !== 'admin')
        .map(customer => ({
          ...customer,
          totalServices: jobs.filter(job => job.userId === customer.id).length
        }));
      
      setCustomers(customersWithServices);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (customer) => {
    setEditingCustomer(customer);
    form.setFieldsValue({
      email: customer.email,
      username: customer.username,
      isBlocked: customer.isBlocked || false,
    });
    setEditModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      await updateDocument("users", editingCustomer.id, {
        // email: values.email,
        username: values.username,
        isBlocked: values.isBlocked,
      });

      message.success("User updated successfully");
      setEditModalVisible(false);
      fetchCustomers();
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("Failed to update user");
    }
  };
  const renderRowFromObj = (item) => {
    return {
      username: item.username,
      email: item.email,
      phone: item.phone,
      totalServices: item.totalServices,
      status: item.isBlocked ? "Blocked" : "Active",
      action: (
        <Button onClick={() => handleEdit(item)}>Edit</Button>
      ),
    };
  };

  const ant_columns = [
    { title: "Name", relatedWith: "username" },
    { title: "Phone Number", relatedWith: "phone" },
    { title: "Email", relatedWith: "email" },
    { title: "No. of total services", relatedWith: "totalServices" },
    { title: "Status", relatedWith: "status" },
    { title: "Action", relatedWith: "action" },
  ];

  return (
    <div className="text-white">
      <h3 className="f-5 text-white mb-3">All Customers</h3>
      <ANT_Table
        itemsPerPage={6}
        data={customers}
        loading={loading}
        className={"dark-table"}
        renderRowFromObj={renderRowFromObj}
        ant_columns={ant_columns}
      />
    <Modal
        // title="Edit User"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditSubmit} layout="vertical">
          <Form.Item
            label={<span>Username</span>}
            name="username"
            rules={[{ required: true, message: 'Please input the username!' }]}
          >
            <Input />
          </Form.Item>
     
     
          <Form.Item
            label="Block User"
            name="isBlocked"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AllCustomers;