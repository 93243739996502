

import { 
  collection, 
  doc, 
  setDoc, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  limit, 
  arrayUnion,
  startAt,
  endAt,
} from 'firebase/firestore';
import { db, storage } from './firebase';
import { notification } from 'antd';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';


// Add a new document to a collection
export const addDocument = async (collectionName, data) => {
  try {
    
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: new Date().toISOString()
    });
    // Update the document to include its ID
    await updateDoc(docRef, { id: docRef.id });
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};
// 
export const uploadImage = async (file, path) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  
  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};
export const setDocumentWithID = async (collectionName, docId, data) => {
  try {
    await setDoc(doc(db, collectionName, docId), {
      ...data,
      id: docId,  // This ensures the id field in the document matches the document ID
    });
    return docId;
  } catch (error) {
    console.error("Error setting document: ", error);
    throw error;
  }
};

// Set a document with a custom ID
export const setDocument = async (collectionName, docId, data) => {
  try {
    await setDoc(doc(db, collectionName, docId), {
      ...data,
      id: docId,
      createdAt: new Date().toISOString()
    });
    return docId;
  } catch (error) {
    console.error("Error setting document: ", error);
    throw error;
  }
};
export const migrateUserDocument = async (oldDocId, newDocId) => {
  try {
    const collectionName = 'users';
    // Get the old document
    const oldDocRef = doc(db, collectionName, oldDocId);
    const oldDocSnap = await getDoc(oldDocRef);

    if (!oldDocSnap.exists()) {
      throw new Error("Document to migrate does not exist");
    }

    const oldData = oldDocSnap.data();

    // Create a new document with the new ID
    const newDocRef = doc(db, collectionName, newDocId);
    await setDoc(newDocRef, {
      ...oldData,
      id: newDocId,
      updatedAt: new Date().toISOString()
    });

    // Delete the old document
    await deleteDoc(oldDocRef);

    return newDocId;
  } catch (error) {
    console.error("Error migrating user document: ", error);
    throw error;
  }
};

// Update an existing document
export const updateDocument = async (collectionName, docId, data) => {
  try {
    const docRef = doc(db, collectionName, docId);
    await updateDoc(docRef, {
      ...data,
      updatedAt: new Date().toISOString()
    });
    return docId;
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

// Delete a document
export const deleteDocument = async (collectionName, docId) => {
  try {
    await deleteDoc(doc(db, collectionName, docId));
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};

// Get a document by ID
export const getDocumentById = async (collectionName, docId) => {
  try {
    const docSnap = await getDoc(doc(db, collectionName, docId));
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    throw error;
  }
};

// Get all documents from a collection
export const getAllDocuments = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting documents: ", error);
    throw error;
  }
};

// Query documents with conditions
export const queryDocuments = async (collectionName, conditions, orderByField, orderDirection = 'asc', limitCount = 100) => {
  try {
    let q = collection(db, collectionName);

    // Add conditions
    conditions.forEach(condition => {
      q = query(q, where(condition.field, condition.operator, condition.value));
    });

    // Add orderBy
    if (orderByField) {
      q = query(q, orderBy(orderByField, orderDirection));
    }

    // Add limit
    q = query(q, limit(limitCount));

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error querying documents: ", error);
    throw error;
  }
};


export const getJobs = async (user) => {
  const jobsCollection = collection(db, 'jobs');
  
  // Query to get jobs where userId matches
  const q = query(jobsCollection, where('userId', '==', user.id));
  
  const jobSnapshot = await getDocs(q);
  return jobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
export const updateJobStatus = async (jobId, status, additionalData = {}) => {
  const jobRef = doc(db, 'jobs', jobId);
  await updateDoc(jobRef, { status, ...additionalData });
};
export const getNotifications = async (userId) => {
  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const markNotificationAsRead = async (notificationId, viewedAt) => {
  const notificationRef = doc(db, 'notifications', notificationId);
  await updateDoc(notificationRef, { 
    isUnread: false,
    viewedAt: viewedAt || new Date().toISOString()
  });
};

export const deleteNotification = async (notificationId) => {
  await deleteDoc(doc(db, 'notifications', notificationId));
};
export const createNotification = async (userId, title, description, type) => {
  await addDoc(collection(db, 'notifications'), {
    userId,
    title,
    description,
    type,
    isUnread: true,
    createdAt: new Date().toISOString()
  });
};