// src/pages/auth/Login.jsx
import React from 'react';
import { Checkbox, Form,  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/common/Input';
import './Login.scss';
import {Button} from '../../components/common/Button';

const NewPassowrd = () => {
  const [form] = Form.useForm();
  const navigate =useNavigate()
  const handleSubmit = () => {
      form.validateFields().then(values => {
        console.log('Form values:', values);
        navigate('/dashboard');
      }).catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });
    };
  return (
    <div className=''>
      <div className="text-center my-5">
      <div className="col-md-6 m-auto">

<img src={require('../../assets/images/fulllogo.png')} className='w-100' />
</div>
</div>

<div className="login-container p-3">
      <div className="login-form-wrapper border-primary">
        <h2 className="login-title">Reset Password!!</h2>
        <p className="login-subtitle">Please enter the new password</p>
        <Form form={form} onFinish={handleSubmit} layout="vertical" className='my-5'>
         
         
          
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input icon="ph:lock" label="Enter New Password" type="password" placeholder="●●●●●●●●" />
          </Form.Item>
          <Form.Item name="confirmPassword" dependencies={['password']} rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}>
            <Input icon="ph:lock" label="Confirm New Password" type="password" placeholder="●●●●●●●●" />
          </Form.Item>
          <div className="d-flex justify-content-between mb-5">
             </div>

          <Form.Item>
            <Button  htmlType="submit" className="sign-up-btn">
              Save New Password
            </Button>
          </Form.Item>
        </Form>
        
        
      </div>
    </div>
    </div>
  );
};

export default NewPassowrd;