import React from "react";
import Routes from "./routes";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <>
      <div className="bg-container"></div>
      <div className="content-container">
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
