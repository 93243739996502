import React, { useState, useEffect } from 'react';
import { Tabs, Row, Col, Input } from 'antd';
import './CustomerDashboard.scss';
import { EmptyState, JobCard } from '../../components/cards';
import { EmptyJobIcon } from '../../assets/svgs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/common/Button';
import { createNotification, getJobs, updateJobStatus } from '../../firebase/firebaseHelpers';
import LoadingComponent from '../../components/LoadingComponent';
import { tab } from '@testing-library/user-event/dist/tab';
import { useAuth } from '../../hooks/useAuth';
import { Select } from '../../components/common/Select';
// import LoadingComponent from '../../components/LoadingComponent';
const serviceList = ["DENTING", "PAINTING", "MECHANICAL", "ELECTRICIAN", "INSPECTION"];

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const CustomerDashboard = () => {
  const [activeTab, setActiveTab] = useState('0');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const navigate = useNavigate();
  const {user }=useAuth()
  const handleCompleteJob = async (jobId) => {
    await updateJobStatus(jobId, 'completed');
    fetchJobs();
  };
  const fetchJobs = async () => {
    setLoading(true);
    try {
      const fetchedJobs = await getJobs(user);
      console.log(fetchedJobs,"fetchedJobs")
      setJobs(fetchedJobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
    const interval = setInterval(() => {
      if (activeTab === '1') {
        fetchJobs();
      }
    }, 30 * 60 * 1000); // 30 minutes
    return () => clearInterval(interval);
  }, [activeTab]);

  const handleCreateJob = () => {
    navigate('/dashboard/create-job');
  };

  const handleCancelJob = async (jobId) => {
    await updateJobStatus(jobId, 'cancelled');
    fetchJobs();
  };

  
  const handlePayment = async (jobId, paymentMethod,job) => {
    await updateJobStatus(jobId, 'paid', { paymentMethod:paymentMethod,invoiceDate: new Date().toISOString() });
    await createNotification(
      job.userId,
      "Payment Received",
      `Payment for job on vehicle ${job.vehicleNumber} has been received.`,
      "payment_received"
    );
    fetchJobs();
  };
  const serviceOptions = serviceList.map(service => ({
    value: service,
    label: service
  }));
  const filteredJobs = jobs.filter(job => {
    if (!searchTerm && !selectedService) return true;
    
    const searchTermLower = searchTerm.toLowerCase();
    const matchesSearchTerm = 
      job.vehicleNumber?.toLowerCase().includes(searchTermLower) ||
      job.phoneNumber?.toLowerCase().includes(searchTermLower) ||
      job.vinNumber?.toLowerCase().includes(searchTermLower) ||
      job.vehicleYear?.toLowerCase().includes(searchTermLower) ||
      (Array.isArray(job.serviceType) && job.serviceType.some(service => 
        service.toLowerCase().includes(searchTermLower)
      ));
    
    const matchesSelectedService = !selectedService || 
      (Array.isArray(job.serviceType) && job?.serviceType?.includes(selectedService));

    return matchesSearchTerm && matchesSelectedService;
  });
  // ABC123
  // 2343245345
  // vinNumber need to add
  // 2020


  const renderJobCards = (status) => {
    const statusJobs = filteredJobs.filter(job => job.status === status);
    
    if (statusJobs.length === 0) {
      return (
        <div className="col-md-6 m-auto">
          <EmptyState 
            customIcon={<EmptyJobIcon width={48} height={48} fill="#1890ff" />}
            message={`No ${status} Jobs`}
            // actionText={status === 'active' ? "Create Job" : null}
            onAction={status === 'active' ? handleCreateJob : null}
          />
        </div>
      );
    }

    return (
      <Row gutter={[16, 16]}>
        {statusJobs.map(job => (
          <Col xs={24} sm={12} md={8} lg={8} key={job.id}>
            <JobCard 
              job={job}
              onCancel={() => handleCancelJob(job.id)}
              onComplete={() => navigate(`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}`)}
              onPay={(id,method) => handlePayment(job.id, method,job)}
              link={`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}` }
              onActivate={() => handleActivateJob(job.id)}

              />
          </Col>
        ))}
      </Row>
    );
  };

  if (loading) {
    return <LoadingComponent />;
  }
  const handleActivateJob = async (jobId) => {
    await updateJobStatus(jobId, 'active');
    fetchJobs();
  };

  return (
    <div className="customer-dashboard">
      <div className="flex justify-between items-center mb-4 flex-wrap">
        <h1>Dashboard</h1>
        <Button className="px-5 py-2" onClick={handleCreateJob}>Create Job</Button>
      </div>
      <div className="row items-center mb-4">
       <div className="col-md-6 mb-3">

       
        <Search
          placeholder="Search jobs by plate number, phone, VIN, car model, or service type..."
          onSearch={setSearchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          size='large'
          // style={{ width: '100%' }}
          />
          </div>
          {activeTab==1
          &&
          <div className="col-md-4 mb-3">
          <div className=" ">
          <Select
          
             placeholder="Filter by department"
             onChange={setSelectedService}
             allowClear
             
              style={{ width: '100%',background:'transparent',height:37 }}
                      
                      options={serviceOptions
                        }
                    />
         
         
          </div>
          </div>
          }
      </div>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
      <TabPane tab="New Jobs" key="0">
          {renderJobCards('new')}
        </TabPane>
        <TabPane tab="Active Jobs" key="1">
       
       
        
          {renderJobCards('active')}

        </TabPane>
        <TabPane tab="Completed Jobs" key="2">
          {renderJobCards('completed')}
        </TabPane>
        <TabPane tab="Paid Jobs" key="3">
          {renderJobCards('paid')}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CustomerDashboard;