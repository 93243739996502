import React, { useState, useContext, useEffect } from 'react';
import { Checkbox, Form, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebase';
import { getDocumentById } from '../../firebase/firebaseHelpers';
import Input from '../../components/common/Input';
import './Login.scss';
import { Button } from '../../components/common/Button';
import { AuthContext } from '../../contexts/AuthContext';

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login,logout } = useContext(AuthContext);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      form.setFieldsValue({ email: rememberedEmail, remember: true });
    }
  }, [form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;
  
      // Fetch user data from Firestore
      const userData = await getDocumentById('users', user.uid);
      
      if (userData) {
        if (userData.isBlocked) {
          // User is blocked, sign them out and show a message
          await logout();
          notification.error({
            message: 'Account Blocked',
            description: 'Your account has been blocked. Please contact support for assistance.',
          });
        } else {
          // User is not blocked, proceed with login
          login(userData);
          
          // Handle "Remember me" functionality
          if (values.remember) {
            localStorage.setItem('rememberedEmail', values.email);
          } else {
            localStorage.removeItem('rememberedEmail');
          }
  
          notification.success({
            message: 'Login Successful',
            description: 'Welcome back!',
          });
          navigate('/dashboard');
        }
      } else {
        throw new Error('User data not found');
      }
    } catch (error) {
      console.error('Login error:', error);
      notification.error({
        message: 'Login Failed',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=''>
      <div className="text-center my-5">
        <div className="col-md-6 m-auto">
          <img src={require('../../assets/images/fulllogo.png')} className='w-100' alt="Logo" />
        </div>
      </div>

      <div className="login-container p-3">
        <div className="login-form-wrapper border-primary">
          <h2 className="login-title">Welcome!</h2>
          <p className="login-subtitle">Hello there! login to get started</p>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
              <Input icon="majesticons:mail-line" label="Email" placeholder="example@gmail.com" />
            </Form.Item>
            
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input icon="ph:lock" label="Enter Password" type="password" placeholder="●●●●●●●●" />
            </Form.Item>
         
            <div className="d-flex justify-content-between ">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox className='text-white'>Remember me</Checkbox>
              </Form.Item>
              <Link to="/forgot-password" className="text-danger">Forgot password?</Link>
            </div>

            <Form.Item>
              <Button htmlType="submit" className="sign-up-btn" loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
          <p className="login-footer">
            {/* Don't have an account? <Link to="/signup">Signup</Link> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;