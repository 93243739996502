// src/routes/PortalRoutes.jsx
import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import PortalLayout from "../components/layout/PortalLayout";
import AdminDashboard from "../pages/admin/Dashboard";
import CustomerDashboard from "../pages/customer/Dashboard";
import ProfileSettings from "../pages/customer/ProfileSettings";
import Notifications from "../pages/customer/Notifications";
import CreateJob from "../pages/customer/CreateJob";
import JobDetails from "../pages/customer/JobDetails";
import GenrateQuotaion from "../pages/customer/GenrateQuotaion";
import SaveJobDetails from "../pages/customer/SaveJobDetails";
import { AuthContext } from "../contexts/AuthContext";
import AllJobs from "../pages/admin/AllJobs";
import AllCustomers from "../pages/admin/AllCustomers";
import AdminNotifications from "../pages/admin/AdminNotifications";
import JobsForApproval from "../pages/admin/JobsForApproval";
import CreateNotification from "../pages/admin/CreateNotification";
import AdminSideJobDetails from "../pages/admin/AdminSideJobDetails";
import AdminProfileSettings from "../pages/admin/AdminProfileSettings";
import Login from "../pages/auth/Login";
import SelectedDateJobs from "../pages/admin/SelectedDateJobs";
// import AdminProfile from '../pages/admin/Profile';
// import CustomerProfile from '../pages/customer/Profile';

// This function would determine the user type based on authentication
const getUserType = (user) => {
  // Implement your logic to determine user type
  // For example, you could check a JWT token or a state management store

  //checking on the basis of type of temporary object

  if (user?.userType == "admin") return "admin";
  else return "customer"; // or 'customer'
};

const PortalRoutes = () => {
  const { user } = useContext(AuthContext);
  const userType = getUserType(user);
  // cons
  const location = useLocation();

  const adminNavItems = [
    {
      path: "/dashboard",
      icon: "mdi:view-dashboard",
      label: "Dashboard",
      component: AdminDashboard,
    },
    {
      icon: "mdi:view-dashboard",
      label: "All Jobs",
      type: "collapse",
      path: "/alljobs",
      component: AllJobs,
      routes: [
        {
          label: "Denting",
          component: AllJobs,
          path: "/alljobs?goto=DENTING",
        },
        {
          label: "Electrician",
          component: AllJobs,
          path: "/alljobs?goto=ELECTRICIAN",
        },
        {
          label: "Mechanical",
          component: AllJobs,
          path: "/alljobs?goto=MECHANICAL",
        },
        {
          label: "Painting",
          component: AllJobs,
          path: "/alljobs?goto=PAINTING",
        },
      ],
    },

    // {
    //   path: "/jobs-for-approval",
    //   component: JobsForApproval,
    //   label: "Jobs for Approval",
    // },
    {
      path: "/job-details/:id",
      component: AdminSideJobDetails,
      notVisible: true,
    },
    {
      path: "/profile-settings",
      component:AdminProfileSettings,
      label: "Profile Settings",
    },
    {
      path: "/allCustomers",
      component: AllCustomers,
      label: "Customer Portal Users",
    },
    {
      path: "/notifications",
      component: AdminNotifications,
      label: "Notifications",
    },
    {
      path: "/login",
      icon: "mdi:view-dashboard",
      label: "Logout",
      component: Login,
    },
    
    {
      path: "/create-notification",
      component: CreateNotification,
      notVisible: true,
    },
    // Add more admin-specific nav items here
  ];

  const customerNavItems = [
    {
      path: "/dashboard",
      icon: "mdi:view-dashboard",
      label: "Dashboard",
      component: CustomerDashboard,
    },
    {
      path: "/porfile",
      icon: "mdi:view-dashboard",
      label: "Porfile Settings",
      component: ProfileSettings,
    },
    {
      path: "/notifications",
      icon: "mdi:view-dashboard",
      label: "Notifications",
      component: Notifications,
    },

    {
      path: "/login",
      icon: "mdi:view-dashboard",
      label: "Logout",
      component: CustomerDashboard,
    },

    // { path: '/create-job', icon: 'mdi:view-dashboard', label: null, component: CreateJob, },

    // { path: '/profile', icon: 'mdi:account', label: 'Profile', component: CustomerProfile },
    // Add more customer-specific nav items here
  ];

  const navItems = userType === "admin" ? adminNavItems : customerNavItems;
  const allRoutes = [
    ...navItems, // 
    { path: '/dashboard/create-job', component: CreateJob }, 
    { path: '/dashboard/job-details/:jobId', component: JobDetails }, 
    { path: '/dashboard/genrate-quotaion/:jobId', component: GenrateQuotaion }, 
    { path: '/dashboard/save-job-details', component: SaveJobDetails }, 
    { path: '/selected-date-jobs/:date/:flowAt', component: SelectedDateJobs }, 


{/* <Route path="/selected-date-jobs/:date/:flowAt" element={<SelectedDateJobs />} /> */}


    
    
  ];
  return (
    <PortalLayout navItems={navItems}>
      <Routes>
        {allRoutes.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={<item.component />}
          />
        ))}
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </PortalLayout>
  );
};

export default PortalRoutes;
