import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getAllDocuments, updateJobStatus } from '../../firebase/firebaseHelpers';
import JobCard from '../../components/cards';
import { Button } from '../../components/common/Button';

const SelectedDateJobs = () => {
  const { date, flowAt } = useParams();
  const [jobsByStatus, setJobsByStatus] = useState({});
  const [fullDate, setFullDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs();
  }, [date, flowAt]);

  const fetchJobs = async () => {
    const allJobs = await getAllDocuments('jobs');
    const { filteredJobs, fullDateStr } = filterJobsByDate(allJobs, date, parseInt(flowAt));
    console.log(filteredJobs, "filteredJobs");
    const groupedJobs = groupJobsByStatus(filteredJobs);
    setJobsByStatus(groupedJobs);
    setFullDate(fullDateStr);
  };

  const filterJobsByDate = (jobs, selectedDate, flowAt) => {
    const currentDate = new Date();
    let filteredJobs = [];
    let fullDateStr = '';

    if (flowAt === 0) { // Weekly
      const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(selectedDate);
      const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
      const targetDate = new Date(startOfWeek.setDate(startOfWeek.getDate() + dayOfWeek));
      
      fullDateStr = `${selectedDate}, ${targetDate.toLocaleDateString()}`;
      
      filteredJobs = jobs.filter(job => {
        const jobDate = new Date(job.submissionDate);
        return jobDate.toDateString() === targetDate.toDateString();
      });
    } else if (flowAt === 1) { // Monthly
      const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(selectedDate);
      fullDateStr = `${selectedDate} ${currentDate.getFullYear()}`;
      
      filteredJobs = jobs.filter(job => {
        const jobDate = new Date(job.submissionDate);
        return jobDate.getMonth() === monthIndex && jobDate.getFullYear() === currentDate.getFullYear();
      });
    } else { // Yearly
      fullDateStr = selectedDate;
      
      filteredJobs = jobs.filter(job => {
        const jobDate = new Date(job.submissionDate);
        return jobDate.getFullYear().toString() === selectedDate;
      });
    }

    return { filteredJobs, fullDateStr };
  };
  const handleCancel = async (jobId) => {
    await updateJobStatus(jobId, 'cancelled');
    fetchJobs();
  };
  const handlePayment = async (jobId, paymentMethod) => {
    await updateJobStatus(jobId, 'paid', { paymentMethod });
    fetchJobs();
  };
  const handleActivate = async (jobId) => {
    await updateJobStatus(jobId, 'active');
    fetchJobs();
  };
  const groupJobsByStatus = (jobs) => {
    return jobs.reduce((acc, job) => {
      if (!acc[job.status]) {
        acc[job.status] = [];
      }
      acc[job.status].push(job);
      return acc;
    }, {});
  };

  const renderJobsByStatus = () => {
    const statusOrder = ['active', 'new','completed','paid' ,'cancelled'];
    return statusOrder.map(status => {
      const jobsForStatus = jobsByStatus[status] || [];
      if (jobsForStatus.length === 0) return null;

      return (
        <div key={status}>
          <h3 className='text-white mt-4 mb-3 capitalize'>  {status.charAt(0).toUpperCase() + status.slice(1)} Jobs
          </h3>
          <Row>
            {jobsForStatus.map(job => (
              <Col key={job.id} md={4} className="mb-3">
               <JobCard
          job={job}
          onCancel={() => handleCancel(job.id)}
          onComplete={() => navigate(`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}`)}
          onPay={(id,method) => handlePayment(job.id, method,job)}
          link={`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}` }
          onActivate={() => handleActivate(job.id)}
        />
              </Col>
            ))}
          </Row>
        </div>
      );
    });
  };

  return (
    <div>
      <Button onClick={() => navigate(-1)}>Back to Dashboard</Button>
      <h4 className='text-white my-4'>Jobs for {fullDate}</h4>
      {renderJobsByStatus()}
    </div>
  );
};

export default SelectedDateJobs;