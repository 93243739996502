import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./JobDetails.scss";
import { BackButton, Button } from "../../components/common/Button";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { getDocumentById } from "../../firebase/firebaseHelpers";
import { Image, Skeleton } from "antd";
import { dummyJobs } from "../../dummyData";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
//this page should be in global routes ,

const JobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [audioElement, setAudioElement] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobData = await getDocumentById("jobs", jobId);
        if (jobData.audio) {
          setAudioElement(jobData.audio);
      }
        if (jobData) setJob(jobData);
        else setJob(dummyJobs[0]); //remove this
      } catch (error) {
        console.error("Error fetching job details:", error);
        // You might want to show an error message to the user here
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className="job-details-container">
      <div className="header">
        <BackButton />
      </div>
      <h3 className="mb-4">Job Details</h3>

      <div className="content">
        <div className="row">
          <div className="col-md-4">
            <section className="vehicle-information">
              <h2>Vehicle Information</h2>
              <div className="">
                <div className='text-md mb-3'>Vehicle Year: <span className='text-sm'>{job.vehicleYear}</span></div>
                <div className='text-md mb-3'>Vehicle Make: <span className='text-sm'>{job.vehicleMake}</span></div>
                <div className='text-md mb-3'>Submission Date: <span className='text-sm'>{job.submissionDate}</span></div>
                <div className='text-md mb-3'>Vehicle Mileage: <span className='text-sm'>{job.vehicleMileage}</span></div>
       
                <div className='text-md mb-3'>VIN Number: <span className='text-sm'>{job.vinNumber}</span></div>
              </div>
            </section>
          </div>

          <div className="col-md-8">
            <div className="row ">
              <div className="col-md-8">
                <div className="media-section h-100">
                  <div className="mb-2" name="images" label={null}>
                    <h2>Images</h2>
                    <div className="border rounded-lg h-100">
                      <div className="row no-gutters h-100">
                        {job?.images?.map((image, index) => (
                          <div key={index} className="col-sm-4 col-6 p-1">
                            <Image
                              style={{ height: 180 }}
                              src={image}
                              className="w-100 object-fit-cover rounded-lg"
                              alt={`Job image ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {audioElement && (
                <div className="audio-player mt-3">
                  <AudioPlayer
    // autoPlay
    src={audioElement}
    onPlay={e => console.log("onPlay")}

    // other props here
  />
  
                </div>
            )}
              </div>
              <div className="col-md-4 h-100">
                <h2>Video</h2>
                <div className="mb-2" name="video" label="Video">
                  <div className="border-primary rounded-lg h-100 position-relative">
                    {job?.video ? (
                      <video
                        style={{ height: 370 }}
                        src={job?.video}
                        className="w-100 object-fit-cover rounded-lg"
                        controls
                      />
                    ) : (
                      <div>No video available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-details">
        <h4 className="text-primary">Contact Details</h4>
        <div className="info-grid">
          <div className='text-md font-bold'>Name: <span className='text-sm font-normal'>{job.customerName}</span></div>
          <div className='text-md font-bold'>Cell: <span className='text-sm font-normal'>{job.phoneNumber}</span></div>
        </div>
        <div className="comments">
          <span className="text-md">Comments:</span>
          <span>{job?.comment}</span>
        </div>
      </section>

      <div className="col-md-5 m-auto py-5">
        <Button
          onClick={() => {
            navigate(`/dashboard/genrate-quotaion/${jobId}`) 
          }}
          className="w-100"
        >
          Generate Quotation
        </Button>
      </div>
    </div>
  );
};

export default JobDetails;
