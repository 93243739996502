import React, { useState } from "react";
import { BackButton, Button } from "../../components/common/Button";
import Input from "../../components/common/Input";
import { Input as AntInput, message } from "antd";
import { addDocument, getAllDocuments } from "../../firebase/firebaseHelpers";

const CreateNotification = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendNotification = async () => {
    if (!title.trim() || !description.trim()) {
      message.error("Please fill in both title and description");
      return;
    }

    setLoading(true);

    try {
      // Get all users
      const users = await getAllDocuments("users");

      // Create a notification for each user
      const notificationPromises = users.map(user => 
        addDocument("notifications", {
          title,
          description,
          type: "admin_notification",
          userId: user.id,
          isUnread: true,
          createdAt: new Date().toISOString()
        })
      );

      await Promise.all(notificationPromises);

      message.success("Notifications sent to all users successfully");
      setTitle("");
      setDescription("");
    } catch (error) {
      console.error("Error sending notifications:", error);
      message.error("Failed to send notifications. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton />
      <h3 className="f-5 text-white my-3 ">Create Notification</h3>
      <div className="p-3 dark-div">
        <h5 className="text-white mb-3">Create Notifications for user</h5>
        <div className="mb-3">
          <Input 
            placeholder="Write title" 
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <AntInput.TextArea 
          className="mb-4" 
          placeholder="Write notification description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button 
          style={{width:'300px'}} 
          className={'py-2'}
          onClick={handleSendNotification}
          loading={loading}
        >
          Send to all users
        </Button>
      </div>
    </div>
  );
};

export default CreateNotification;