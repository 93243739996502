import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, Input, Button } from "antd";
import { Link, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./PortalLayout.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { Collapse } from "reactstrap";
import { RxCaretDown } from "react-icons/rx";
import { RxCaretUp } from "react-icons/rx";
import { useAuth } from "../../hooks/useAuth";
import { getAllDocuments } from "../../firebase/firebaseHelpers";

const { Sider, Content } = Layout;
const { Search } = Input;

export const SideBar = ({ navItems, toggleSidebar }) => {
  const visibleNavItems = navItems?.filter((item) => !item?.notVisible);
  const { user } = useAuth();
const navigate=useNavigate()
const [todayUpdate, setTodayUpdate] = useState({ carsIn: 0, carsOut: 0 });
const { login, logout } = useContext(AuthContext);
  const [collapseOpenAt, setCollpaseOpenAt] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
      if (window.innerWidth >= 992) {
        // setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const location = useLocation();
  const selectedKey = navItems?.find((item) => {

    let subRouteActive = () => {
      return item?.routes?.find((sr) => sr?.path === location.pathname);
    };

    return location.pathname === item?.path || subRouteActive();
  })?.path;
  
  useEffect(() => {
    if (user?.userType === "admin") {
      fetchTodayUpdate();
    }
  }, [user]);

  const fetchTodayUpdate = async () => {
    try {
      const jobs = await getAllDocuments("jobs");
      const today = new Date().toISOString().split('T')[0]; 

      const todayJobs = jobs.filter(job => job.submissionDate === today);
      const carsIn = todayJobs.filter(job => job.status === "active").length;
      const carsOut = todayJobs.filter(job => job.status === "completed" || job.status === "paid").length;

      setTodayUpdate({ carsIn, carsOut });
    } catch (error) {
      console.error("Error fetching today's update:", error);
    }
  };
  return (
    <div>
      <div className="sidebar-logo">
        <img
          src={require("../../assets/images/SideBarLogo.png")}
          className="w-100 object-fit-contain mt-3"
          height={89}
          alt=""
        />
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        {visibleNavItems?.map((item, index) =>
          item?.type === "collapse" ? (
            <React.Fragment key={item?.path}>
              <Menu.Item
                style={{ height: "50px" }}
                key={item?.path}
                onClick={() => {setCollpaseOpenAt(collapseOpenAt === index ? null : index)
navigate(item.path)
if(isSmallScreen){

  toggleSidebar()
}


                }}
              >
                <Link
                  to={item?.path}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item?.path === "/login" ||item?.path === "/admin/login") {
                      logout();
                    }
                  }}
                  className="text-md d-flex justify-content-between align-items-center"
                >
                  {item?.label}{" "}
                  {collapseOpenAt === index ? (
                    <RxCaretUp size={24} />
                  ) : (
                    <RxCaretDown size={24} />
                  )}
                </Link>
              </Menu.Item>
              <div
                style={{ display: collapseOpenAt === index ? "block" : "none" }}
              >
                {item?.routes?.map((subRoute, subRouteIndex) => (
                  <Menu.Item
                    style={{ height: "50px" }}
                    key={subRoute?.path}
                    onClick={() => {
                      if(isSmallScreen){

                        toggleSidebar()
                      }  
                    }}

                  >
                    <Link
                      to={subRoute?.path}
                      className="text-md mx-4"
                    >
                      {subRoute?.label}
                    </Link>
                  </Menu.Item>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <Menu.Item key={item?.path} style={{ height: "50px" }}>
              <Link
                onClick={() => {
                  if(isSmallScreen){

                    toggleSidebar()
                  }
                  setCollpaseOpenAt(null);

                  if (item?.path === "/login") {
                    logout();
                  }
                }}
                className="text-md"
                to={item?.path}
              >
                {item?.label}
              </Link>
            </Menu.Item>
          )
        )}

{user?.userType === "admin" && (
          <div className="dark-div p-3 my-3 mx-2">
            <h5 className="mb-3"> Today's Update</h5>
            <p className="p-1 text-white">No of cars in : {todayUpdate.carsIn}</p>
            <p className="p-1 text-white">No of cars out : {todayUpdate.carsOut}</p>
          </div>
        )}
      </Menu>
    </div>
  );
};