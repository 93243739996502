import dummyProfilePhoto from "./assets/images/dummy-profile.jpg";

export const dashboardChartCardOptions = [
  { title: `As of ${new Date().toLocaleDateString()}`, flowAt: 0 },
  { title: `Last 6 months`, flowAt: 1 },
  { title: `Last 30 days`, flowAt: 2 },
];
export const departments = [
  { title: "Denting", icon: require("./assets/images/dent.png") },
  { title: "Painting", icon: require("./assets/images/paint.png") },
  { title: "Mechanical", icon: require("./assets/images/mechanic.png") },
  { title: "Electrician", icon: require("./assets/images/electrician.png") },
  { title: "Inspection", icon: require("./assets/images/check-car.png") },
];

export const dummyJobs = [
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Painting",
  },
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Denting",
  },
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Inspection",
  },
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Inspection",
  },
  {
    id: `2389238923=09392`,
    status: "completed",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Painting",
  },
  {
    id: `2389238923=09392`,
    status: "paid",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Denting",
  },
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Inspection",
  },
  {
    id: `2389238923=09392`,
    status: "active",
    carModel: "Honda CV 2020",
    submissionDate: new Date()?.toLocaleDateString(),
    completionTimeLine: new Date()?.toLocaleDateString(),
    serviceType: "Inspection",
  },
];

export const dummyCustomers = [
  {
    name: "Saif",
    profilePhoto: dummyProfilePhoto,
    email: "saif1@example.com",
    phoneNo: "+923434333401",
    totalServices: 20,
  },
  {
    name: "Ahmed",
    profilePhoto: dummyProfilePhoto,
    email: "ahmed1@example.com",
    phoneNo: "+923434333402",
    totalServices: 20,
  },
  {
    name: "Ali",
    profilePhoto: dummyProfilePhoto,
    email: "ali1@example.com",
    phoneNo: "+923434333403",
    totalServices: 20,
  },
  {
    name: "Omar",
    profilePhoto: dummyProfilePhoto,
    email: "omar1@example.com",
    phoneNo: "+923434333404",
    totalServices: 20,
  },
  {
    name: "Hassan",
    profilePhoto: dummyProfilePhoto,
    email: "hassan1@example.com",
    phoneNo: "+923434333405",
    totalServices: 20,
  },
  {
    name: "Yasir",
    profilePhoto: dummyProfilePhoto,
    email: "yasir1@example.com",
    phoneNo: "+923434333406",
    totalServices: 20,
  },
  {
    name: "Bilal",
    profilePhoto: dummyProfilePhoto,
    email: "bilal1@example.com",
    phoneNo: "+923434333407",
    totalServices: 20,
  },
  {
    name: "Zaid",
    profilePhoto: dummyProfilePhoto,
    email: "zaid1@example.com",
    phoneNo: "+923434333408",
    totalServices: 20,
  },
  {
    name: "Jamal",
    profilePhoto: dummyProfilePhoto,
    email: "jamal1@example.com",
    phoneNo: "+923434333409",
    totalServices: 20,
  },
  {
    name: "Rizwan",
    profilePhoto: dummyProfilePhoto,
    email: "rizwan1@example.com",
    phoneNo: "+923434333410",
    totalServices: 20,
  },
  {
    name: "Zaid",
    profilePhoto: dummyProfilePhoto,
    email: "zaid1@example.com",
    phoneNo: "+923434333408",
    totalServices: 20,
  },
  {
    name: "Jamal",
    profilePhoto: dummyProfilePhoto,
    email: "jamal1@example.com",
    phoneNo: "+923434333409",
    totalServices: 20,
  },
  {
    name: "Rizwan",
    profilePhoto: dummyProfilePhoto,
    email: "rizwan1@example.com",
    phoneNo: "+923434333410",
    totalServices: 20,
  },
];
export const dummyJobsForApproval = [
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
  {
    date: new Date()?.toLocaleDateString(),
    vehicleName: "XYZ",
    serviceNeeded: "Mechanics",
    cost: 2332,
  },
];

export const chartOptions = {
  options: {
    chart: {
      id: "basic-bar",
      background: "transparent",
      toolbar: { show: false },
    },

    yaxis: {
      labels: {
        style: { colors: "#FFFFFF" },
      },
      opposite: true,
      axisBorder: {
        show: false, // Hides the border of the x-axis
      },
      axisTicks: {
        show: false, // Hides the ticks of the x-axis
      },
    },
    grid: { show: false },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      labels: {
        style: {
          colors: "#FFFFFF", // White color for x-axis labels
        },
      },
      axisBorder: {
        show: false, // Hides the border of the x-axis
      },
      axisTicks: {
        show: false, // Hides the ticks of the x-axis
      },
    },
    colors: ["#B41515"], // Red color for the bars
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: 20,
      },
    },
  },
  series: [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91, 100],
    },
  ],
};

export const dummyJobDetails = {
  id: "NX8qhh3hhGNhpxUkAvVu",
  userId: "tc8X9qty8EXTR2VY4pKd8vsa8sx2",
  grandTotal: "229.32",
  submissionTime: "08:00:00",
  discount: "9",
  vehicleName: "Corolla",
  createdAt: "2024-09-10T04:56:28.909Z",
  completionTimeline: "23",
  submissionDate: "2024-09-23",
  vehicleNumber: "ABC123",
  subject: "complaint",
  vehicleMake: "toyota",
  vehicleYear: "2020",
  serviceType: ["PAINTING"],
  updatedBy: "shujaurr",
  phoneNumber: "2343245345",
  invoiceDate: "2024-09-11T13:14:14.852Z",
  audio:
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/audio%2Fundefined?alt=media&token=4765f9eb-105c-414d-b6ce-5c5100d72113",
  video:
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/videos%2Fvideo1003898703.mp4?alt=media&token=cdb0b857-49a9-44bb-9144-c536ccf433b7",
  vehiclePart: "Engine",
  status: "paid",
  services: [
    {
      total: 8,
      service: "22",
      package: "8",
      quantity: 1,
      partType: "Used",
      finalImage:
        "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/service-images%2FHomepage%20Dashboard%20vv4.jpg?alt=media&token=0679f9af-8a60-4fb1-82b7-4033d43e4d1e",
    },
    {
      total: 244,
      finalImage:
        "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/service-images%2Fimage-256x256%20(2).jpg?alt=media&token=ec5e6bb2-13f5-45e0-8c66-0829643f410a",
      partType: "OEM",
      quantity: "2",
      package: "122",
      service: "Tire",
    },
  ],
  images: [
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2FHomepage%20Dashboard%20vv4.jpg?alt=media&token=fc6ab043-0771-40cb-be68-931053b9ecff",
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2FLogo.png?alt=media&token=3c4dbf33-b05c-45d9-a7d1-224799dab0ef",
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2Fimage-256x256%20(3).jpg?alt=media&token=aaa129c1-199f-4725-9082-672b004c63f1",
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2Fimage-256x256%20(2).jpg?alt=media&token=73770aa0-8b92-4d08-aaeb-50ef960b2b25",
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2Fimage-256x256%20(1).jpg?alt=media&token=6cd6a1c0-8492-49f3-9026-ed714a408c85",
    "https://firebasestorage.googleapis.com/v0/b/saluteautomechanic.appspot.com/o/images%2Fimage-256x256.jpg?alt=media&token=4c641aa8-7ff6-44b6-a186-2da1f45426ee",
  ],
  comment:
    "     Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum unde qui consequuntur enim deserunt aut perferendis aspernatur commodi obcaecati nihil ad corporis libero atque aliquid ratione nobis non quia, nam molestias perspiciatis blanditiis optio culpa? Quaerat laborum porro magnam ad, neque reiciendis quas, expedita labore qui saepe veritatis? Exercitationem quasi asperiores possimus maiores animi obcaecati dolorum unde tempora velit impedit! Corporis culpa iste eos eius repudiandae. Aperiam enim commodi magnam, aliquam maxime magni iste consectetur. Exercitationem necessitatibus nobis praesentium asperiores qui? Nulla quo commodi aliquam consequatur quisquam error nobis dicta tempore sunt modi. Repellat, quam expedita totam sint fuga ex?",
  emailAddress: "johndoe@example.com",
  vehicleMileage: "1500",
  customerName: "John Doe",
  updatedAt: "2024-09-11T13:14:05.428Z",
  paymentMethod: "bankTransfer",
};
