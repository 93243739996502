import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "./PortalLayout.scss";
import { SideBar } from "./SideBar";
import HeaderComponenet from "./HeaderComponenet";
import { PageTransitionIndicator } from "../../utils";

const { Sider, Content, Header } = Layout;

const PortalLayout = ({ children, navItems }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
      if (window.innerWidth >= 992) {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout 
    className="portal-layout"
    style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        className="sidebar"
        width={250}
        collapsedWidth={0}
        theme="dark"
        breakpoint="lg"
        trigger={null}
      >
        <div className="sidebar-inner">
          <SideBar navItems={navItems} toggleSidebar={toggleSidebar} collapsed={collapsed} isSmallScreen={isSmallScreen}/>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
        <Button
        
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={toggleSidebar}
            style={{
              zIndex:99999,
              fontSize: '16px',
              width: 64,
              position:'relative',
              right:!collapsed && isSmallScreen?-243:null,
              height: 64,
              color: 'white'
            }}
          />

          <HeaderComponenet collapsed={collapsed} toggleSidebar={toggleSidebar}/>
        </Header>
        <PageTransitionIndicator />
        <Content className="main-content" style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PortalLayout;