import { ButtonGroup } from "react-bootstrap";
import { Button } from "../../components/common/Button";
import "./Notifications.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { deleteNotification, getAllDocuments, getNotifications, markNotificationAsRead } from "../../firebase/firebaseHelpers";
import { message } from "antd";

const NotificationItem = ({ notification, onDelete, onView }) => (
  <div className={`notification-item ${notification.isUnread ? 'unread' : ''}`}>
    <div className="notification-content">
      <h3 className="notification-title">{notification.title}</h3>
      <p className="notification-description">{notification.description}</p>
    </div>
    <div className="notification-actions">
      {notification.viewedAt ? (
        <span className="notification-date"> {new Date(notification.viewedAt).toLocaleDateString()}</span>
      ) : (
        <Button isSmall className="view-btn" onClick={() => onView(notification)}>View</Button>
      )}
      <Button isSmall isSecondary className="delete-btn" onClick={() => onDelete(notification.id)}>Delete</Button>
    </div>
  </div>
);

const AdminNotifications = () => {
  const navigateTo = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchNotifications = async () => {
        try {
          const fetchedNotifications = await getAllDocuments("notifications");
          const uniqueNotifications = fetchedNotifications.filter(
            (value, index, self) => index === self.findIndex((n) => n.description === value.description)
          );
          
          setNotifications(uniqueNotifications);
        } catch (error) {
          console.error("Error fetching notifications:", error);
          // message.error("Failed to load notifications");
        } finally {
          setLoading(false);
        }
      
    };

    fetchNotifications();
  }, []);
  
  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotification(notificationId);
      setNotifications(notifications.filter(n => n.id !== notificationId));
      message.success("Notification deleted");
    } catch (error) {
      console.error("Error deleting notification:", error);
      message.error("Failed to delete notification");
    }
  };

  const handleViewNotification = async (notification) => {
    try {
      if (notification.isUnread) {
        const viewedAt = new Date().toISOString();
        await markNotificationAsRead(notification.id, viewedAt);
        setNotifications(notifications.map(n => 
          n.id === notification.id ? { ...n, isUnread: false, viewedAt } : n
        ));
      }
      // Here you can add logic to navigate to the relevant page based on the notification type
      console.log("Viewing notification:", notification);
    } catch (error) {
      console.error("Error marking notification as read:", error);
      message.error("Failed to mark notification as read");
    }
  };
  const handleMarkAllAsRead = async () => {
    try {
      await Promise.all(notifications.filter(n => n.isUnread).map(n => markNotificationAsRead(n.id)));
      setNotifications(notifications.map(n => ({ ...n, isUnread: false })));
      message.success("All notifications marked as read");
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
      message.error("Failed to mark all notifications as read");
    }
  };

  const unreadCount = notifications.filter(n => n.isUnread).length;

  if (loading) {
    return <div>Loading notifications...</div>;
  }
  if (notifications.length === 0) {
    return (
      <div className="notifications-container empty-state">
        <h2>Notifications</h2>
        <p>You don't have any notifications at the moment.</p>
        <p>When you receive new notifications, they will appear here.</p>
      </div>
    );
  }

  return (
    <div className="notifications-container">
      <div className="notifications-header mb-3 gap-3">
        <div>
          <h3 className="f-5 text-white mb-3 ">Notifications</h3>
          <p className='mt-4'>You have {unreadCount} unread notifications</p>
        </div>
        <div className="header-actions">
          <Button
            className={"py-2 br-0"}
            onClick={() => navigateTo("/create-notification")}
            isSmall
          >
            Create Notification
          </Button>
          <Button isSmall isSecondary onClick={handleMarkAllAsRead}>Mark all as read</Button>

        </div>
      </div>
      <div className="notifications-list">
      {notifications.map((notification) => (
          <NotificationItem 
            key={notification.id} 
            notification={notification} 
            onDelete={handleDeleteNotification}
            onView={handleViewNotification}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminNotifications;
