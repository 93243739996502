import { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./JobDetails.scss";
import { BackButton, Button } from "../../components/common/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getDocumentById } from "../../firebase/firebaseHelpers";
import { Skeleton } from "antd";
import { dummyJobDetails, dummyJobs } from "../../dummyData";
import { MdOutlineCloudDownload } from "react-icons/md";
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import { ANT_Table } from "../../utils";

const AdminSideJobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobData = await getDocumentById("jobs", jobId);
        if (jobData) setJob(jobData);
        else setJob(dummyJobDetails); //remove this
      } catch (error) {
        console.error("Error fetching job details:", error);
        setJob(dummyJobDetails); //remove this
        // You might want to show an error message to the user here
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, []);

  if (loading) {
    return <Skeleton active />;
  }
  return (
    <>
      <div className="job-details-container">
        <div className="header">
          <BackButton />
        </div>
        <div className="d-flex mb-4 align-items-center justify-content-between">
          <h3 className="m-0 text-primary"> Details</h3>
          <div>
            {job?.status === "completed" && (
              <div className="bagde">Completed</div>
            )}
            <Button
              isSecondary
              className={"border-none d-flex gap-3 align-items-center"}
            >
              <MdOutlineCloudDownload size={20} /> Download Pdf
            </Button>
          </div>
        </div>

        <div className="content">
          <div className="row">
            <div className="col-md-4">
              <section className="vehicle-information">
                <div className="">
                  <div className="text-md mb-3">
                    Vehicle Year:{" "}
                    <span className="text-sm">{job?.vehicleYear}</span>
                  </div>
                  <div className="text-md mb-3">
                    Vehicle Make:{" "}
                    <span className="text-sm">{job?.vehicleMake}</span>
                  </div>
                  <div className="text-md mb-3">
                    Submission Date:{" "}
                    <span className="text-sm">{job?.submissionDate}</span>
                  </div>
                  <div className="text-md mb-3">
                    Vehicle Mileage:{" "}
                    <span className="text-sm">{job?.vehicleMileage}</span>
                  </div>
                  <div className="text-md mb-3">
                    Vehicle Part:{" "}
                    <span className="text-sm">{job?.vehiclePart}</span>
                  </div>
                </div>
              </section>
            </div>

            <div className="col-md-8">
              <div className="row ">
                <div className="col-md-8">
                  <div className="media-section h-100">
                    <div className="mb-2" name="images" label={null}>
                      <h2>Images</h2>
                      <div className="border rounded-lg h-100">
                        <div className="row no-gutters h-100">
                          {job?.images?.map((image, index) => (
                            <div key={index} className="col-sm-4 col-6 p-1">
                              <img
                                onClick={() => setImageModalOpen(true)}
                                style={{ height: 180 }}
                                src={image}
                                className="w-100 object-fit-cover rounded-lg"
                                alt={`Job image ${index + 1}`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 h-100">
                  <h2>Video</h2>
                  <div className="mb-2" name="video" label="Video">
                    <div className="border-primary rounded-lg h-100 position-relative">
                      {job?.video ? (
                        <video
                          style={{ height: 370 }}
                          src={job?.video}
                          className="w-100 object-fit-cover rounded-lg"
                          controls
                        />
                      ) : (
                        <div>No video available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="mb-3 text-primary">Services Required</h3>
              {job?.services?.map((item, key) => (
                <div className="row mb-0">
                  <div className="col-md-3">
                    <h6>Service type </h6>
                    <p className="text-white m-0">{item?.service}</p>
                  </div>
                  <div className="col-md-3">
                    <h6>Completion Timeline </h6>
                    <p className="text-white m-0">24 hours</p>
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-white">Description </h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Debitis rem, repellendus accusantium commodi odit iste.
                      Tempora odit molestias quod accusamus, quo, sequi, veniam
                      exercitationem perspiciatis doloribus ullam sed eligendi
                      obcaecati!
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{ border: "1px solid white" }}
            className=" p-2 rounded-lg"
          >
            <ANT_Table
              className={"full-transparent-table"}
              noPagination={true}
              data={job?.services}
              renderRowFromObj={(item) => {
                return {
                  service: item?.service,
                  quantity: item?.quantity,
                  partType: item?.partType,
                  package: item?.package,
                  total: item?.total,
                  action: <Button isSecondary>Upload Image</Button>,
                };
              }}
              ant_columns={[
                { title: "Services", relatedWith: "service" },
                { title: "Quantity", relatedWith: "quantity" },
                { title: "Part Type", relatedWith: "partType" },
                { title: "Package", relatedWith: "package" },
                { title: "Total", relatedWith: "total" },
                { title: "Upload Image", relatedWith: "action" },
              ]}
            />
            <div className="p-3">
              <h5>Grand Total</h5>
              <h4>$Rs.5500</h4>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onCancel={() => setImageModalOpen(false)}
        footer={null}
        open={imageModalOpen}
      >
        <Button
          onClick={() => setImageModalOpen(false)}
          className="py-1 border-none my-3"
        >
          <Icon fontSize={30} icon={"iconamoon:arrow-left-2-bold"} />
        </Button>
        <img
          src={job?.images[0]}
          height={300}
          className="full-width object-fit-cover rounded-lg"
        />
        <h4 className="mt-3 mb-0 p-2 text-white">Details</h4>
        <div className="p-2 row g-3  text-white ">
          <div className="col-md-6">
            <h6 className="m-0 f-5">Service : Tire</h6>
          </div>
          <div className="col-md-6">
            <h6 className="m-0 f-5">PartType : Tire</h6>
          </div>
          <div className="col-md-6">
            <h6 className="m-0 f-5">Quantity : 2</h6>
          </div>
          <div className="col-md-6">
            <h6 className="m-0 f-5">Total : $300</h6>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminSideJobDetails;
