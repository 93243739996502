import React from 'react';
import { Input as AntInput ,TimePicker as AntTimePicker} from 'antd';
import { Icon } from '@iconify/react';
import './Input.scss';

const Input = ({ icon, label, className, disabled,Disabledbordered, ...rest }) => {
  return (
    <div className={`custom-input-wrapper ${disabled ? 'disabled' : ''}`}>
      {icon && (
        <div className="input-icon-wrapper">
          <Icon icon={icon} className="input-icon" color={disabled ? '#666666' : '#B41515'} />
        </div>
      )}
      <div className="input-content">
        {label && <label className="input-label">{label}</label>}
        <AntInput
          {...rest}
          className={`custom-input ${className || ''} ${disabled ? 'disabled' : ''}`}
          bordered={!disabled}
          disabled={disabled||Disabledbordered}
        />
      </div>
    </div>
  );
};

export default Input;
export const TimePicker = ({ icon, label, className, disabled, ...rest }) => {
  return (
    <div className={`custom-input-wrapper ${disabled ? 'disabled' : ''}`}>
      {icon && (
        <div className="input-icon-wrapper">
          <Icon icon={icon} className="input-icon" color={disabled ? '#666666' : '#B41515'} />
        </div>
      )}
      <div className="input-content">
        {label && <label className="input-label">{label}</label>}
        <AntTimePicker
          {...rest}
          className={`custom-input ${className || ''} ${disabled ? 'disabled' : ''}`}
          bordered={!disabled}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
