import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../assets/images/fulllogo.png';

const styles = StyleSheet.create({
  page: { padding: 30, fontFamily: 'Helvetica' },
  header: { marginBottom: 20, backgroundColor: "#575858", padding: 20, borderRadius: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  logo: { width: '100%', height: 'auto' },
  title: { fontSize: 18, fontWeight: 'bold', marginBottom: 20, color: '#333' },
  infoRow: { flexDirection: 'row', marginBottom: 5 },
  infoLabel: { width: 150, fontWeight: 'bold', color: '#555',fontSize: 18, },
  infoValue: { flex: 1,fontSize: 18 },
  table: { display: 'table', width: 'auto', borderStyle: 'solid', borderWidth: 1, borderColor: '#bfbfbf',borderBottom:0,borderBottomWidth:0, marginTop: 20 },
  tableRow: { flexDirection: 'row', },
  tableCol: { width: '20%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#bfbfbf' },
  tableCell: { margin: 5, fontSize: 10 },
  tableHeader: { backgroundColor: '#f0f0f0', fontWeight: 'bold' },
  totalsSection: { marginTop: 20, borderTop: 1, borderTopColor: '#bfbfbf', paddingTop: 10 },
  totalRow: { flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 },
  totalLabel: { width: 150, textAlign: 'right', fontWeight: 'bold', marginRight: 10 },
  totalValue: { width: 100, textAlign: 'right' },
  grandTotal: { marginTop: 10, borderTop: 1, borderTopColor: '#bfbfbf', paddingTop: 5 },
  footer: { marginTop: 30, backgroundColor: "#575858", padding: 15, borderRadius: 10, color: '#fff', fontSize: 10, textAlign: 'center' },
  preparedSection: { marginTop: 20, flexDirection: 'row', justifyContent: 'space-between' },
  preparedItem: { flexDirection: 'column' },
  preparedLabel: { fontWeight: 'bold', fontSize: 10, color: '#555' },
  preparedValue: { fontSize: 10 },
});

const InvoicePDF = ({ job }) => {
  const calculateTotals = (services, discount = 0) => {
    const subtotal = services.reduce((sum, service) => sum + (service.quantity * service.package), 0);
    const discountAmount = subtotal * (parseFloat(discount) / 100);
    const discountedSubtotal = subtotal - discountAmount;
    const tax = discountedSubtotal * 0.05; // 5% tax
    const grandTotal = discountedSubtotal + tax;
    return { subtotal, discountAmount, discountedSubtotal, tax, grandTotal };
  };
  // const { subtotal, discountAmount, discountedSubtotal, tax, grandTotal } = calculateTotals(job?.services);
  const { subtotal, discountAmount, discountedSubtotal, tax, grandTotal } = calculateTotals(job?.services, job?.discount);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          {/* <Text style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>Auto Mechanic Workshop</Text> */}
        </View>

        <Text style={styles.title}>Job Details</Text>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Vehicle Name:</Text>
              <Text style={styles.infoValue}>{job?.vehicleName}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Vehicle No:</Text>
              <Text style={styles.infoValue}>{job?.vehicleNumber}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Vehicle Year:</Text>
              <Text style={styles.infoValue}>{job?.vehicleYear}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Vehicle Make:</Text>
              <Text style={styles.infoValue}>{job?.vehicleMake}</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Submission Date:</Text>
              <Text style={styles.infoValue}>{job?.submissionDate}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Vehicle Mileage:</Text>
              <Text style={styles.infoValue}>{job?.vehicleMileage}</Text>
            </View>
            <View style={styles.infoRow}>
  <Text style={styles.infoLabel}>Service:</Text>
  <Text style={styles.infoValue}>
    {job?.serviceType?.join(', ') ?? 'No service type'}
  </Text>
</View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>VIN Number:</Text>
              <Text style={styles.infoValue}>{job?.vinNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Service</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Quantity</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Part Type</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Price</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Total</Text></View>
          </View>
          {job?.services.map((service, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{service.service}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{service.quantity}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{service.partType}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>AED {Number(service.package).toFixed(2)}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>AED {(service.quantity * service.package).toFixed(2)}</Text></View>
            </View>
          ))}
        </View>

        <View style={styles.totalsSection}>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Subtotal:</Text>
            <Text style={styles.totalValue}>AED {subtotal.toFixed(2)}</Text>
          </View>
          {job?.discount && job?.discount !== '0' && (
            <View style={styles.totalRow}>
              <Text style={styles.totalLabel}>Discount ({job?.discount}%):</Text>
              <Text style={styles.totalValue}>AED {discountAmount.toFixed(2)}</Text>
            </View>
          )}
          {job?.discount && job?.discount !== '0' && (
            <View style={styles.totalRow}>
              <Text style={styles.totalLabel}>Discounted Subtotal:</Text>
              <Text style={styles.totalValue}>AED {discountedSubtotal.toFixed(2)}</Text>
            </View>
          )}
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Tax (5%):</Text>
            <Text style={styles.totalValue}>AED {tax.toFixed(2)}</Text>
          </View>
          <View style={[styles.totalRow, styles.grandTotal]}>
            <Text style={[styles.totalLabel, { fontWeight: 'bold' }]}>Grand Total:</Text>
            <Text style={[styles.totalValue, { fontWeight: 'bold' }]}>AED {grandTotal.toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.preparedSection}>
          <View style={styles.preparedItem}>
            <Text style={styles.preparedLabel}>Prepared By:</Text>
            <Text style={styles.preparedValue}>{job?.updatedBy}</Text>
          </View>
          <View style={styles.preparedItem}>
            <Text style={styles.preparedLabel}>Prepared For:</Text>
            <Text style={styles.preparedValue}>{job?.customerName}</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>+971 54 447 5677 | salute_auto | saluteauto10@gmail.com | Dubai - United Arab Emirates</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;