import React from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

export const Button = ({
  loading = false,
  isSmall,
  style,
  className,
  isSecondary = false,
  isSuccess = false,
  size = 'md',
  iconSize,
  icon,
  children,
  htmlType = 'reset',
  ...props
}) => {
  return (
    <BootstrapButton
      disabled={loading}
      style={{
        background: isSecondary ? '#696867' : isSuccess ? '#006500' : '',
        border: isSecondary ? '1px solid #fff' : isSuccess ? '1px solid #006500' : '',
        paddingTop: isSmall ? 5 : 12,
        paddingBottom: isSmall ? 5 : 12,
        ...style
      }}
      type={htmlType}
      {...props}
      size={size}
      className={`${className || ''}`}
    >
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {icon && <Icon fontSize={iconSize} icon={icon} className="mr-2" />}
          {children}
        </>
      )}
    </BootstrapButton>
  );
};

export const BackButton = ({ isSecondary = false, size = 'md', iconSize, icon, children, htmlType = 'submit', ...props }) => {
  const navigate = useNavigate()
  return (
    <BootstrapButton
      onClick={() => navigate(-1)}
      style={{
        background: isSecondary ? '#696867' : '',
        border: isSecondary ? '1px solid #fff' : ''
      }}
      type={htmlType}
      {...props}
      size={size}
      className='p-1'
    >
      <Icon fontSize={30} icon={'iconamoon:arrow-left-2-bold'} />
    </BootstrapButton>
  );
};