import React, { useState, useEffect } from 'react';
import { Form, Input as AntInput, Spin, message, Modal, Upload, Image } from 'antd';
import './JobDetails.scss';
import { BackButton, Button } from '../../components/common/Button';
import Input from '../../components/common/Input';
import { Select } from '../../components/common/Select';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import { SuccessModal } from '../../components/cards';
import SubmitWorkModal from '../../components/modals/SubmitWorkModal';
import ImageDetailsModal from '../../components/modals/ImageDetailsModal';
import { createNotification, getDocumentById, updateDocument, uploadImage } from '../../firebase/firebaseHelpers';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoicePDF from '../../components/InvoicePDF';
const SaveJobDetails = () => {
    const [form] = Form.useForm();
    // const { jobId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    
    const isActive = urlParams.get('status') === 'completed';
const jobId = urlParams.get('jobId') ;

    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isSubmitWorkModalVisible, setIsSubmitWorkModalVisible] = useState(false);
    const [isImageDetailsModalVisible, setIsImageDetailsModalVisible] = useState(false);
    const [selectedImageData, setSelectedImageData] = useState(null);
    const [audioElement, setAudioElement] = useState(null);

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const jobData = await getDocumentById('jobs', jobId);
                setJob(jobData);
                form.setFieldsValue(jobData);
                if (jobData.audio) {
                    setAudioElement(jobData.audio);
                }
            } catch (error) {
                console.error("Error fetching job details:", error);
                message.error("Failed to load job details");
            } finally {
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId, form]);

    const handleImageClick = (imageData) => {
        setSelectedImageData(imageData);
        setIsImageDetailsModalVisible(true);
    };

    const handleMarkAsDone = () => {
      if (job?.services?.every(service => service?.finalImage)) {
          setIsSubmitWorkModalVisible(true);
      } else {
          message.error("Please upload images for all services before marking as done.");
      }
  }
    const handleSubmitWorkModalClose = async (finalImages) => {
        setSubmitting(true);
        try {
            // const updatedServices = job?.services?.map((service, index) => ({
            //     ...service,
            //     finalImage: finalImages[index] || null
            // }));
            // const updatedServices = job.services.map((service, index) => ({
            //   ...service,
            //   finalImage: uploadedImages[index] || service.finalImage
            // }));
            await updateDocument('jobs', jobId, {
                ...job,
                status: 'completed'
            });
            await createNotification(
              job.userId,
              "Job Completed",
              `Your job for vehicle ${job.vehicleNumber} has been completed.`,
              "job_completion"
            );

            setIsSubmitWorkModalVisible(false);
            setIsSuccessModalVisible(true);
        } catch (error) {
            console.error("Error updating job:", error);
            message.error("Failed to update job");
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccessModalClose = () => {
        setIsSuccessModalVisible(false);
        navigate('/dashboard');
    };

    const handleCancelJob = () => {
      Modal.confirm({
          title: <span className='text-white' >Are you sure you want to cancel this job?</span>,
          onOk: async () => {
              try {
                  await updateDocument('jobs', jobId, { status: 'cancelled' });
                  await createNotification(
                    job.userId,
                    "Job cancelled",
                    `Your job for vehicle ${job.vehicleNumber} has been cancelled.`,
                    "job_cancelled"
                  );
                  message.success("Job cancelled successfully");
                  navigate('/dashboard');
              } catch (error) {
                  console.error("Error cancelling job:", error);
                  message.error("Failed to cancel job");
              }
          }
      });
  };
 

  
const handleImageUpload = async (file, serviceIndex) => {
  try {
    setDisableBtn(true)
      const imageUrl = await uploadImage(file, 'service-images');
      const updatedServices = [...job?.services];
      updatedServices[serviceIndex].finalImage = imageUrl;
      await updateDocument('jobs', jobId, { services: updatedServices });
      setJob({ ...job, services: updatedServices });
    setDisableBtn(false)

      message.success("Image uploaded successfully");
  } catch (error) {
    setDisableBtn(false)

      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
  }
};
const calculateTotals = (services, discount = 0) => {
    const subtotal = services.reduce((sum, service) => sum + (service.quantity * service.package), 0);
    const discountAmount = subtotal * (parseFloat(discount) / 100);
    const discountedSubtotal = subtotal - discountAmount;
    const tax = discountedSubtotal * 0.05; // 5% tax
    const grandTotal = discountedSubtotal + tax;
    return { subtotal, discountAmount, discountedSubtotal, tax, grandTotal };
  };
  const { subtotal, discountAmount, discountedSubtotal, tax, grandTotal } = calculateTotals(job?.services || [], job?.discount);


if (loading) {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh'  // This ensures the height takes the full screen
    }}>
      <Spin size="large" />
    </div>
  );
}
    return (
        <div className="job-details-container">
            <div className="header d-flex justify-content-between flex-wrap">
                <BackButton onClick={() => navigate(-1)} />
                <div className="d-flex mt-3 gap-3">
                    {job?.status === 'completed' ||job?.status === 'paid' && (
                        <Button className="py-2 px-5 mr-3 text-capitalize"  isSuccess>
                            {job?.status} {job?.status === 'paid'?`Using ${job.paymentMethod}`:''}
                        </Button>
                    )}
                    
                    <PDFDownloadLink document={<InvoicePDF job={job} />} fileName="invoice.pdf">
  {({ blob, url, loading, error }) =>
    loading ? 'Loading document...' :     <Button className="py-2 px-5"
      // onClick={handleDownloadPdf}
      iconSize={24} icon={'bytesize:download'} isSecondary>
          Download PDF
      </Button> 
  }
</PDFDownloadLink>
                 
                </div>
            </div>
            <div id="job-details-content" className="pdf-content">

            <h3 className='mb-4 text-primary'>Details</h3>
            
            <div className="content">
                <div className="row">
                    <div className="col-md-4">
                        <div className="">
                            <div className='text-sm font-bold mb-2'>Vehicle Name: <span className='text-sm font-normal'>{job?.vehicleName}</span></div>
                            <div className='text-sm font-bold mb-2'>Vehicle No: <span className='text-sm font-normal'>{job?.vehicleNumber}</span></div>
                            <div className='text-sm font-bold mb-2'>Vehicle Year: <span className='font-normal text-sm'>{job?.vehicleYear}</span></div>
                            <div className='text-sm font-bold mb-2'>Vehicle Make: <span className='font-normal text-sm'>{job?.vehicleMake}</span></div>
                            <div className='text-sm font-bold mb-2'>Submission Date: <span className='font-normal text-sm'>{job?.submissionDate}</span></div>
                            <div className='text-sm font-bold mb-2'>Vehicle Mileage: <span className='font-normal text-sm'>{job?.vehicleMileage}</span></div>
                            <div className='text-sm font-bold mb-2'>Service: <span className='font-normal text-sm'>{job?.serviceType?.join(', ')}</span></div>
                            <div className='text-sm font-bold mb-2'>VIN Number: <span className='font-normal text-sm'>{job?.vinNumber}</span></div>



                            {audioElement && (
                <div className="audio-player mt-3">
                  <AudioPlayer
    // autoPlay
    src={audioElement}
    onPlay={e => console.log("onPlay")}

    // other props here
  />
  
                </div>
            )}
                        </div>
                    </div>
                    
                    <div className="col-md-8">
                        <div className="row ">
                            <div className="col-md-8">
                                <div className="media-section h-100">
                                    <div className="mb-2" name="images">
                                        <div className="border rounded-lg h-100">
                                            <div className="row no-gutters h-100">
                                                {job?.images.map((image, index) => (
                                                    <div className="col-md-4 col-6 p-1" key={index}>
                                                        <Image
                                                            style={{height:180}}
                                                            src={image}
                                                            className="w-100 object-fit-cover rounded-lg"
                                                            alt={`Job image ${index + 1}`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 h-100">
                                <div className="mb-2" name="video" label="Video">
                                    <div className="border-primary rounded-lg h-100 position-relative">
                                        {job?.video ? (
                                            <video
                                                style={{height:370}}
                                                src={job?.video}
                                                className="w-100 object-fit-cover rounded-lg"
                                                controls
                                            />
                                        ) : (
                                            <div className="text-center p-4">No video available</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         

            <div className="header d-flex justify-content-between flex-wrap ">
                <h4 className="mb-4 text-primary ">Service Required</h4>
                {job.status === 'active' && (
  <Button 
    className="py-2 px-5" 
    iconSize={24} 
    isSecondary 
    onClick={() => navigate(`/dashboard/genrate-quotaion/${jobId}?update=true`)}
  >
    Edit
    <Icon fontSize={22} className='ml-3' icon={"lucide:edit"} />
  </Button>
)}
            </div>
            
            <Form form={form} layout="vertical">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item name="serviceType">
                                    <Input defaultValue={job?.serviceType?.join(', ')} disabled label="Service Type" />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="completionTimeline">
                                    <Select
                                        defaultValue={job?.completionTimeline}
                                        disabled
                                        label={"Completion Timeline (hours)"}
                                        options={[
                                            { value: '24hours', label: '24 hours' },
                                            { value: '48hours', label: '48 hours' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item name="submissionTime">
                                    <Input disabled defaultValue={job?.submissionTime} label="Submission Time" />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="discount">
                                    <Input disabled defaultValue={`${job?.discount}%`} label="Discount %" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Form.Item className="mb-2" name="comment" label={<span className='text-white'>Description</span>}>
                            <p className='text-white-50'>{job?.comment}</p>
                        </Form.Item>
                    </div>
                </div>
                <div className="border-primary rounded-lg p-3 table-container"  >
                    <div className="row">
                        <div className="col m-auto text-center">
                            <p className='font-bold text-white mb-0'>Services</p>
                        </div>
                        <div className="col m-auto text-center">
                            <p className='font-bold text-white mb-0'>Quantity</p>
                        </div>
                        <div className="col m-auto text-center">
                            <p className='font-bold text-white mb-0'>Part type</p>
                        </div>
                        <div className="col d-flex justify-content-between align-items-center">
                            <p className='font-bold text-white mb-0'>Package</p>
                        </div>
                        <div className="col m-auto text-center">
                            <p className='font-bold text-white mb-0'>Upload Image</p>
                        </div>
                    </div>
                    {job?.services?.map((service, index) => (
                        <div className="row mt-2" key={index}>
                            <div className="col m-auto text-center">
                                <Input disabled className="py-2 text-center" size="large" value={service.service} />
                            </div>
                            <div className="col m-auto text-center">
                                <Input disabled className="py-2 text-center" size="large" value={service.quantity} />
                            </div>
                            <div className="col m-auto text-center">
                                <Input disabled className="py-2 text-center" size="large" value={service.partType} />
                            </div>
                            <div className="col d-flex justify-content-between align-items-center">
                                <Input disabled className="py-2 " size="large" value={service.package} />
                            </div>
                            <div className="col m-auto text-center">
                                {job?.status === 'completed' ||job?.status === 'paid' ? (
                                    <img
                                        onClick={() => handleImageClick({
                                            src: service.finalImage,
                                            service: service.service,
                                            partType: service.partType,
                                            quantity: service.quantity,
                                            total: service.total
                                        })}
                                        style={{ cursor: 'pointer', width: '50%' }}
                                        className='rounded-lg border'
                                        src={service.finalImage || require('../../assets/images/SideBarLogo.png')}
                                        alt={service.service}
                                    />
                                ) : (
                                  <Upload
    maxCount={1}

                                  listType="picture-card"
                                //   className='w-100'
                                style={{minWidth:300}}
                  // fileList={imageList}
                  accept="image/jpeg,image/png,image/jpg"
                                  beforeUpload={(file) => {
                                    handleImageUpload(file, index);
                                    return false;
                                  }}
                                >
                                  <Button className="py-1 px-2 text-sm" iconSize={20} icon={'bytesize:upload'} isSecondary>
                                    Upload Image
                                  </Button>
                                </Upload>
                                )}
                            </div>
                        </div>
                    ))}
                
       
      
                <div className="row">
  <div className="col">
    <p className='text-white text-md font-bold px-4 pt-4'>Subtotal: AED {subtotal.toFixed(2)}</p>
  </div>
</div>
{job?.discount && job?.discount !== '0' && (
  <>
    <div className="row">
      <div className="col">
        <p className='text-white text-md font-bold px-4'>Discount ({job?.discount}%): AED {discountAmount.toFixed(2)}</p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <p className='text-white text-md font-bold px-4'>Discounted Subtotal: AED {discountedSubtotal.toFixed(2)}</p>
      </div>
    </div>
  </>
)}
<div className="row">
  <div className="col">
    <p className='text-white text-md font-bold px-4'>Tax (5%): AED {tax.toFixed(2)}</p>
  </div>
</div>
<div className="row">
  <div className="col">
    <p className='text-white text-lg font-bold px-4'>Grand Total (including 5% tax): AED {grandTotal.toFixed(2)}</p>
  </div>
</div>
             </div>
                <p className='mt-3 text-white text-base'>
                    <span className='font-bold'>Prepared By:</span> {job?.updatedBy}
                </p>
                <p className='mt-3 text-white text-base'>
                    <span className='font-bold'>Prepared For:</span> {job?.customerName}
                </p>
                <div className="col-md-8 m-auto">
                    {job?.status === 'active' && (
                        <div className="row">
                            <div className="col-md-6 m-auto py-5">
                                <Button isSecondary className="w-100" onClick={handleCancelJob}>
                                    Cancel Job
                                </Button>
                            </div>
                            <div className="col-md-6 m-auto py-5">
                                <Button className="w-100" onClick={handleMarkAsDone}>
                                    Mark As Done
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Form>
</div>
            
            <SubmitWorkModal
                isVisible={isSubmitWorkModalVisible}
                onClose={()=>setIsSubmitWorkModalVisible(false)}
                job={job}
                userId={job?.userId} 
                handleSubmitWorkModalClose={handleSubmitWorkModalClose}

                />
            
            <SuccessModal
                isVisible={isSuccessModalVisible}
                onClose={handleSuccessModalClose}
                message="Job submitted successfully"
                
            />

            {selectedImageData && (
                <ImageDetailsModal
                    isVisible={isImageDetailsModalVisible}
                    onClose={() => setIsImageDetailsModalVisible(false)}
                    imageData={selectedImageData}
                    />
                )}
            </div>
        );
    };
    
    export default SaveJobDetails;