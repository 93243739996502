import { Alert, Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const useScreenWidth = (breakPoint) => {
  const [isSmall, setisSmall] = useState(
    window.innerWidth <= breakPoint ? true : false
  );
  useEffect(() => {
    const resizeListener = () => {
      if (window?.innerWidth <= breakPoint) {
        setisSmall(true);
      } else setisSmall(false);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return { isSmall };
};

export const retriveFromLocalStorage = (key, expectedFormate) => {
  let value = localStorage.getItem(key);
  switch (expectedFormate) {
    case "json": {
      let isValidJSON = false;
      try {
        isValidJSON = true;
        return JSON.parse(value);
      } catch (e) {
        isValidJSON = false;
        console.error(
          `Retrieved value is not valid JSON ${value} type ${typeof value} `
        );
        return null;
      }
    }
    case "string": {
      return value;
    }
  }
};

//ant-desgin simplefiers
/**simple Table component to simplify and extend the use of AntD Table Component
 * All you need to do is create table columns for ant_columns like {title:"",relatedWith:'keyName'}
 * @param renderRowFromObj recieves the item from data array which you can map with same keyNames 
 * @example see usage
  */
export const ANT_Table = ({
  renderRowFromObj,
  ant_rows,
  ant_columns,
  data,
  className,
  rowClassName,
  itemsPerPage,
  noPagination,
}) => {
  const { isSmall } = useScreenWidth(ant_columns?.length >= 4 ? 578 : 768);

  const columns = ant_columns?.map((item, key) => ({
    dataIndex: item?.relatedWith,
    title: item?.title,
    key: key,
  }));

  if (!ant_rows && !renderRowFromObj) {
    return (
      <div
        style={{
          height: "300px",
          background: "crimson",
          color: "white",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        ant_rows or renderRowFromObj are expected to render rows
      </div>
    );
  }

  let enteries = [];
  if (!ant_rows && renderRowFromObj) {
    console.log("Rendering the row using renderRowFromObj");
    enteries = data?.map((item) => renderRowFromObj(item));
  } else enteries = ant_rows;

  return (
    <Table
      pagination={!noPagination ? { pageSize: itemsPerPage } : false}
      className={className}
      rowClassName={rowClassName}
      columns={columns}
      onRow={(d) => {}}
      scroll={{ scrollToFirstRowOnChange: true }}
      dataSource={enteries}
    ></Table>
  );
};

//

export const PageTransitionIndicator = () => {
  const { pathname, search } = useLocation();
  const [isAnimating, setisAnimating] = useState(false);

  useEffect(() => {
    setisAnimating(true);
  }, [pathname, search]);

  return (
    <div
      onAnimationEnd={() => setisAnimating(false)}
      style={{
        display: isAnimating ? "block" : "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        height: 3,
        width: "100%",
        background: "#B41515",
        animation: isAnimating ? "from-zero-to-full 1s linear 1" : "",
      }}
    ></div>
  );
};
