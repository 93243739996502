import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Spin } from 'antd';
import {
  DashboardChartCard,
  DashboardDisplayCard,
  DepartmentsCard,
} from "../../components/cards";
import { departments } from "../../dummyData";
import { getAllDocuments, queryDocuments } from '../../firebase/firebaseHelpers';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    totalCustomers: 0,
    revenueChange: 0,
    ordersChange: 0,
    customersChange: 0
  });

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      
      const currentDate = new Date();
      const lastMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));

      const allJobs = await getAllDocuments('jobs');
      const lastMonthJobs = await queryDocuments('jobs', [
        { field: 'submissionDate', operator: '>=', value: lastMonthDate.toISOString().split('T')[0] }
      ]);

      const calculateTotals = (jobs) => {
        const revenue = jobs.reduce((sum, job) => sum + (parseFloat(job.grandTotal) || 0), 0);
        const uniqueCustomers = new Set(jobs.map(job => job.userId)).size;
        return { revenue, orders: jobs.length, customers: uniqueCustomers };
      };

      const allTotals = calculateTotals(allJobs);
      const lastMonthTotals = calculateTotals(lastMonthJobs);

      setDashboardData({
        totalRevenue: allTotals.revenue,
        totalOrders: allTotals.orders,
        totalCustomers: allTotals.customers,
        revenueChange: lastMonthTotals.revenue,
        ordersChange: lastMonthTotals.orders,
        customersChange: lastMonthTotals.customers
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center h-100">
      <Spin size="large" />
    </div>;
  }

  return (
    <div>
      <h3 className="f-5 text-white mb-3">Dashboard</h3>
      <Row className="g-3">
        <Col md={4}>
          <DashboardDisplayCard
            title={"Total Revenue"}
            headlineText={"since last month"}
            headlineFocus={`${dashboardData.revenueChange.toFixed(2)}`}
            subtitle={`${dashboardData.totalRevenue.toFixed(2)}`}
          />
        </Col>
        <Col md={4}>
          <DashboardDisplayCard
            title={"Total Orders"}
            headlineText={"since last month"}
            headlineFocus={`+${dashboardData.ordersChange}`}
            subtitle={dashboardData.totalOrders.toString()}
          />
        </Col>
        <Col md={4}>
          <DashboardDisplayCard
            title={"Total Customers"}
            headlineText={"since last month"}
            headlineFocus={`+${dashboardData.customersChange}`}
            subtitle={dashboardData.totalCustomers.toString()}
          />
        </Col>
        <Col md={8}>
          <DashboardChartCard title={"Customer trend"} />
        </Col>
        <Col md={4}>
          <DepartmentsCard departments={departments} />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;