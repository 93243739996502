import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BackButton } from "../../components/common/Button";
import JobCard, { EmptyState } from "../../components/cards";
import { Button} from "react-bootstrap";
import { Col, Dropdown, Row } from "antd";
import { FaCaretDown } from "react-icons/fa";
import { getAllDocuments, getJobs, updateJobStatus } from "../../firebase/firebaseHelpers";
import { EmptyJobIcon } from "../../assets/svgs";
import Search from "antd/es/input/Search";

const AllJobs = () => {
  const [params] = useSearchParams();
  const type = params.get("goto");
  const [activeTab, setActiveTab] = useState('new');
  const [jobs, setJobs] = useState([]);
  const [timeFilter, setTimeFilter] = useState('This Year');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs();
  }, [type]);
  console.log(type,"type")

  const fetchJobs = async () => {
    try {
      const fetchedJobs = await getAllDocuments("jobs");
      console.log(type===null? fetchedJobs :fetchedJobs?.filter(job => job?.serviceType?.includes(type)))
      setJobs(type===null? fetchedJobs :fetchedJobs?.filter(job => job?.serviceType?.includes(type)));
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  const filterJobs = (jobs) => {
    const timeFilteredJobs = filterJobsByTime(jobs);
    return timeFilteredJobs.filter(job => {
      const searchTermLower = searchTerm.toLowerCase();
      const matchesSearchTerm = 
        job.vehicleNumber?.toLowerCase().includes(searchTermLower) ||
        job.phoneNumber?.toLowerCase().includes(searchTermLower) ||
        job.vinNumber?.toLowerCase().includes(searchTermLower) ||
        job.vehicleYear?.toLowerCase().includes(searchTermLower) ||
        (Array.isArray(job?.serviceType) && job?.serviceType?.some(service => 
          service.toLowerCase().includes(searchTermLower)
        ));

      let statusMatch;
      switch(activeTab) {
        case "new":
          statusMatch = job.status === "new";
          break;
        case "active":
          statusMatch = job.status === "active";
          break;
        case "completed":
          statusMatch = job.status === "completed";
          break;
        case "paid":
          statusMatch = job.status === "paid";
          break;
        default:
          statusMatch = true;
      }

      return matchesSearchTerm && statusMatch;
    });
  };


  const handleCancel = async (jobId) => {
    await updateJobStatus(jobId, 'cancelled');
    fetchJobs();
  };

  const handleComplete = async (jobId) => {
    await updateJobStatus(jobId, 'completed');
    fetchJobs();
  };

  const handlePayment = async (jobId, paymentMethod) => {
    await updateJobStatus(jobId, 'paid', { paymentMethod });
    fetchJobs();
  };

  const handleActivate = async (jobId) => {
    await updateJobStatus(jobId, 'active');
    fetchJobs();    
  };

  const filterJobsByTime = (jobs) => {
    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    return jobs.filter(job => {
      // Handle invalid date
      const jobDate = job.submissionDate !== "Invalid date" ? new Date(job.submissionDate) : new Date();
      
      switch(timeFilter) {
        case 'This Week':
          return jobDate >= startOfWeek;
        case 'This Month':
          return jobDate >= startOfMonth;
        case 'This Year':
          return jobDate >= startOfYear;
        default:
          return true;
      }
    });
  };

  const renderJobCards = () => {
    const filteredJobs = filterJobs(jobs);

    if (filteredJobs.length === 0) {
      return (
        <div className="col-md-6 m-auto">
          <EmptyState 
            customIcon={<EmptyJobIcon width={48} height={48} fill="#1890ff" />}
            message={`No Jobs Found`}
          />
        </div>
      );
    }

    return filteredJobs.map((job, key) => (
      <Col xs={24} sm={12} md={8} lg={8} key={job.id}>
        <JobCard
          job={job}
          onCancel={() => handleCancel(job.id)}
          onComplete={() => navigate(`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}`)}
          onPay={(id,method) => handlePayment(job.id, method,job)}
          link={`/dashboard/save-job-details?status=${job.status}&jobId=${job.id}` }
          onActivate={() => handleActivate(job.id)}
        />
      </Col>
    ));
  };
  
  
  const renderTitle = () => {
    switch (type) {
      case "ELECTRICIAN":
        return "Jobs for Electrician";
      case "DENTING":
        return "Jobs for Denting";
      case "PAINTING":
        return "Jobs for Painting";
      case "MECHANICAL":
        return "Jobs for Mechanic";
        // "INSPECTION"
      default:
        return null;
    }
  };

  return (
    <div className="text-white">
      {/* <div className="mb-3 d-flex align-items-center">
        <BackButton />
      </div> */}
      <div>
        <h3 className="text-white mb-3">{renderTitle()}</h3>
        <div className="col-md-6">

        <Search
          placeholder="Search jobs by plate number, phone, VIN, car model, or service type..."
          onSearch={setSearchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          size='large'
          className="mb-3"
          />
          </div>
        <div className="d-flex align-items-center flex-wrap-reverse flex-md-nowrap gap-3 justify-content-center justify-content-md-between">
          <div className="red-nav-btns d-flex align-items-center ">
            <Button
              className={activeTab === "new" ? "active" : ""}
              onClick={() => setActiveTab("new")}
            >
              New Jobs
            </Button>
            <Button
              className={activeTab === "active" ? "active" : ""}
              onClick={() => setActiveTab("active")}
            >
              Active Jobs
            </Button>
            <Button
              className={activeTab === "completed" ? "active" : ""}
              onClick={() => setActiveTab("completed")}
            >
              Completed Jobs
            </Button>
            <Button
              className={activeTab === "paid" ? "active" : ""}
              onClick={() => setActiveTab("paid")}
            >
              Paid Jobs
            </Button>
          </div>
          <Dropdown
        // style={{borderColor:'yellow'}}
            menu={{
              items: [
                { label: "This Week", key: 0 },
                { label: "This Month", key: 1 },
                { label: "This Year", key: 2 },
              ],
              selectable: true,
              defaultSelectedKeys: [2],
              onClick: ({ key, keyPath, domEvent }) => {
                setTimeFilter(["This Week", "This Month", "This Year"][key]);
              },
            }}
          >
            <Button
              className="bg-transparent text-white"
              style={{ width: 200,borderColor:'#fff' }}
            >
              {timeFilter} <FaCaretDown />
            </Button>
          </Dropdown>
        </div>
        <Row gutter={[16, 16]} className="g-3 mt-3">{renderJobCards()}</Row>
      </div>
    </div>
  );
};

export default AllJobs;