import React, { useState } from 'react';
import { Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/firebase';
import Input from '../../components/common/Input';
import './Login.scss';
import { Button } from '../../components/common/Button';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      notification.success({
        message: 'Reset Email Sent',
        description: 'Please check your email for the reset link.',
      });
      // navigate('/code-verification', { state: { email: values.email } });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=''>
      <div className="text-center my-5">
        <div className="col-md-6 m-auto">
          <img src={require('../../assets/images/fulllogo.png')} className='w-100' alt="Logo" />
        </div>
      </div>

      <div className="login-container p-3">
        <div className="login-form-wrapper border-primary">
          <h2 className="login-title">Forgot password!!</h2>
          <p className="login-subtitle">Please enter your email address</p>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="email" className='my-5' rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
              <Input icon="majesticons:mail-line" label="Email" placeholder="example@gmail.com" />
            </Form.Item>

            <Form.Item>
              <Button htmlType='submit' loading={loading} className="sign-up-btn">
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;