import React from 'react';
import { Modal } from 'antd';
import { Icon } from '@iconify/react';
import './ImageDetailsModal.scss';
import { BackButton } from '../common/Button';

const ImageDetailsModal = ({ isVisible, onClose, imageData }) => {
  if (!imageData) return null;

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      closeIcon={null}
      width={400}
      className="image-details-modal"
    >
      <div className="d-flex mb-3">
        <BackButton
          onClick={onClose}
          className="py-0"
          style={{ height: 38 }}
        />
        <h2 className="text-white pl-4">Image</h2>
      </div>
      <div className="image-container">
        <img 
          src={imageData.finalImage || imageData.src} 
          alt="Service" 
          className="service-image rounded-lg" 
        />
      </div>
      <div className="details-container text-left">
        <h3 className="details-title text-left">Details</h3>
        <div className="details-grid">
          <div className="">
            <span className="text-white font-bold mr-3">Service:</span>
            <span className="text-white-50">{imageData.service}</span>
          </div>
          <div className="">
            <span className="text-white font-bold mr-3">Part Type:</span>
            <span className="text-white-50">{imageData.partType}</span>
          </div>
          <div className="">
            <span className="text-white font-bold mr-3">Quantity:</span>
            <span className="text-white-50">{imageData.quantity}</span>
          </div>
          <div className="">
            <span className="text-white font-bold mr-3">Total:</span>
            <span className="text-white-50">{imageData.total}</span>
          </div>
          {imageData.status === 'completed' && (
            <div className="">
              <span className="text-white font-bold mr-3">Status:</span>
              <span className="text-white-50">Completed</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImageDetailsModal;