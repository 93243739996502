import React, { useState, useEffect } from 'react';
import { Button } from '../../components/common/Button';
import './Notifications.scss';
import { useAuth } from '../../hooks/useAuth';
import { getNotifications, markNotificationAsRead, deleteNotification } from '../../firebase/firebaseHelpers';
import { message } from 'antd';

const NotificationItem = ({ notification, onDelete, onView }) => (
  <div className={`notification-item ${notification.isUnread ? 'unread' : ''}`}>
    <div className="notification-content">
      <h3 className="notification-title">{notification.title}</h3>
      <p className="notification-description">{notification.description}</p>
    </div>
    <div className="notification-actions">
      {notification.viewedAt ? (
        <span className="notification-date"> {new Date(notification.viewedAt).toLocaleDateString()}</span>
      ) : (
        <Button isSmall className="view-btn" onClick={() => onView(notification)}>View</Button>
      )}
      <Button isSmall isSecondary className="delete-btn" onClick={() => onDelete(notification.id)}>Delete</Button>
    </div>
  </div>
);
const Notifications = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user) {
        try {
          const fetchedNotifications = await getNotifications(user.id);
          setNotifications(fetchedNotifications);
        } catch (error) {
          console.error("Error fetching notifications:", error);
          // message.error("Failed to load notifications");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchNotifications();
  }, [user]);

  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotification(notificationId);
      setNotifications(notifications.filter(n => n.id !== notificationId));
      message.success("Notification deleted");
    } catch (error) {
      console.error("Error deleting notification:", error);
      message.error("Failed to delete notification");
    }
  };

  const handleViewNotification = async (notification) => {
    try {
      if (notification.isUnread) {
        const viewedAt = new Date().toISOString();
        await markNotificationAsRead(notification.id, viewedAt);
        setNotifications(notifications.map(n => 
          n.id === notification.id ? { ...n, isUnread: false, viewedAt } : n
        ));
      }
      // Here you can add logic to navigate to the relevant page based on the notification type
      console.log("Viewing notification:", notification);
    } catch (error) {
      console.error("Error marking notification as read:", error);
      message.error("Failed to mark notification as read");
    }
  };
  const handleMarkAllAsRead = async () => {
    try {
      await Promise.all(notifications.filter(n => n.isUnread).map(n => markNotificationAsRead(n.id)));
      setNotifications(notifications.map(n => ({ ...n, isUnread: false })));
      message.success("All notifications marked as read");
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
      message.error("Failed to mark all notifications as read");
    }
  };

  const unreadCount = notifications.filter(n => n.isUnread).length;

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  if (notifications.length === 0) {
    return (
      <div className="notifications-container empty-state">
        <h2>Notifications</h2>
        <p>You don't have any notifications at the moment.</p>
        <p>When you receive new notifications, they will appear here.</p>
      </div>
    );
  }

  return (
    <div className="notifications-container">
      <div className="notifications-header">
        <div>
          <h2>Notifications</h2>
          <p className='mt-4'>You have {unreadCount} unread notifications</p>
        </div>
        <div className="header-actions">
          <Button isSmall isSecondary onClick={handleMarkAllAsRead}>Mark all as read</Button>
        </div>
      </div>
      <div className="notifications-list">
        {notifications.map((notification) => (
          <NotificationItem 
            key={notification.id} 
            notification={notification} 
            onDelete={handleDeleteNotification}
            onView={handleViewNotification}
          />
        ))}
      </div>
    </div>
  );
};

export default Notifications;