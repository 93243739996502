import React, { useState, useEffect } from 'react';
import { Form, Modal, Upload, message } from 'antd';
import { Button } from '../common/Button';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getDocumentById, updateDocument, uploadImage } from '../../firebase/firebaseHelpers';

const SubmitWorkModal = ({ isVisible, onClose, job, userId,handleSubmitWorkModalClose }) => {
  const [user, setUser] = useState(null);
  const [uploadedImages, setUploadedImages] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getDocumentById("users",userId);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (userId) fetchUser();
  }, [userId]);

  const handleImageUpload = async (file, serviceIndex) => {
    try {
      const imageUrl = await uploadImage(file, 'service-images');
      setUploadedImages(prev => ({ ...prev, [serviceIndex]: imageUrl }));
      message.success(`Image for ${job.services[serviceIndex].service} uploaded successfully`);
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error(`Failed to upload image for ${job.services[serviceIndex].service}`);
    }
  };

  const handleSubmit = async () => {
    try {
      const updatedServices = job.services.map((service, index) => ({
        ...service,
        finalImage: uploadedImages[index] || service.finalImage
      }));
      

      await updateDocument('jobs', job.id, { 
        services: updatedServices, 
        status: 'completed' 
      });

      message.success("Job marked as completed successfully");
      onClose();
    } catch (error) {
      console.error("Error completing job:", error);
      message.error("Failed to complete job");
    }
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      // closable={false}
      centered
      width={1000}
    >
      <div className="text-white" style={{textAlign:'left'}}>
        <h2 className="mb-4">Submit Work</h2>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-8">
              <div className="media-section h-100">
                <Form.Item
                  className="mb-2"
                  name="images"
                  label={<span className='text-white'>Upload Images</span>}
                >
                  <div className="border rounded-lg h-100">
                    <div className="row no-gutters h-100">
                      {job.images.map((service, index) => (
                        <div className="col-md-4 p-1" key={index}>
                          {uploadedImages[index] || service ? (
                            <img
                              style={{height: 180}}
                              src={service}
                              className="w-100 object-fit-cover rounded-lg"
                              alt={service.service}
                            />
                          ) : (
                            <Upload.Dragger
                              className="bg-secondary h-100"
                              beforeUpload={(file) => {
                                handleImageUpload(file, index);
                                return false;
                              }}
                            >
                              <p className="ant-upload-drag-icon d-flex justify-content-center align-items-center h-100">
                                <div>
                                  <p className="text-white text-lg font-normal">
                                    Upload Image for {service.service}
                                  </p>
                                  <Icon
                                    icon="mdi:cloud-upload"
                                    className="text-primary"
                                    fontSize={50}
                                  />
                                </div>
                              </p>
                            </Upload.Dragger>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-4">
              <Form.Item className="mb-2" name="video" label={<span className='text-white'>Upload Video</span>}>
                <div className="border-primary rounded-lg h-100 position-relative">
                  {job.video ? (
                    <video
                      style={{height: 370}}
                      src={job.video}
                      className="w-100 object-fit-cover rounded-lg"
                      controls
                    />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <p className="text-white">No video available</p>
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
        
        <h4 className='mb-4 text-primary'>Details</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <div className='text-sm font-bold mb-2'>Vehicle Name: <span className='text-sm font-normal'>{job.vehicleName}</span></div>
              <div className='text-sm font-bold mb-2'>Vehicle No: <span className='text-sm font-normal'>{job.vehicleNumber}</span></div>
              <div className='text-sm font-bold mb-2'>Vehicle Year: <span className='font-normal text-sm'>{job.vehicleYear}</span></div>
              <div className='text-sm font-bold mb-2'>Vehicle Make: <span className='font-normal text-sm'>{job.vehicleMake}</span></div>
              <div className='text-sm font-bold mb-2'>Vehicle Make: <span className='font-normal text-sm'>{job.vehicleMake}</span></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className='text-sm font-bold mb-2'>Vehicle Mileage: <span className='font-normal text-sm'>{job.vehicleMileage}</span></div>
            <div className='text-sm font-bold mb-2'>Service: <span className='font-normal text-sm'>{job?.serviceType?.join(', ')}</span></div>
            <div className='text-sm font-bold mb-2'>Submission Date: <span className='font-normal text-sm'>{job.submissionDate}</span></div>
          </div>
        </div>
        
        <h4 className='mb-4 text-primary'>Client's Contact</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <div className='text-sm font-bold mb-2'>Name: <span className='text-sm font-normal'>{job.customerName}</span></div>
              <div className='text-sm font-bold mb-2'>Email: <span className='text-sm font-normal'>{job.emailAddress}</span></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className='text-sm font-bold mb-2'>Cell: <span className='font-normal text-sm'>{job.phoneNumber}</span></div>
          </div>
        </div>
        
        <div className="text-center">
          <Button onClick={()=>handleSubmitWorkModalClose()} className="w-75 mt-3">
            Submit Work
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitWorkModal;